import { Fragment } from 'react';

import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { IStateList, ListComponent } from 'components/Abstract/List';
import ErrorMessage from 'components/Shared/ErrorMessage';
import { IStyledProps, WithStyles } from 'decorators/withStyles';
import IOlxGroup from 'interfaces/models/olxGroup';
import IProperty, { enPropertyStatus } from 'interfaces/models/property';
import { IPaginationParams } from 'interfaces/pagination';
import bindComponent from 'rxjs-operators/bindComponent';
import propertyService from 'services/property';

import PropertyListItem from '../PropertyItem';

interface IState extends IStateList<IProperty & { added?: boolean }> {
  loading: boolean;
  error?: any;
}

interface IProps extends IStyledProps {
  opened: boolean;
  group: IOlxGroup;
  onComplete: () => void;
}

@WithStyles(theme => ({
  content: {
    padding: 0
  },
  actions: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0
  }
}))
export default class PropertyAddDialog extends ListComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props, 'createdDate');
    this.state = { ...this.state, pageSize: 5, loading: true };
  }

  loadData = (params: Partial<IPaginationParams> = {}) => {
    this.setState({ loading: true, error: null });

    propertyService
      .list({ ...this.mergeParams(params), status: enPropertyStatus.pending })
      .pipe(bindComponent(this))
      .subscribe({
        next: items => {
          items.results = items.results.map(r => ({
            ...r,
            added: this.props.group.properties.some(p => p.id === r.id)
          }));

          this.setPaginatedData(items);
        },
        error: error => this.setError(error)
      });
  };

  handleAddChange = (property: IProperty, added: boolean) => {
    const { items } = this.state;

    this.setState({
      items: items.map(i => {
        if (i.id === property.id) {
          return { ...i, added };
        }

        return i;
      })
    });
  };

  handleEnter = () => this.loadData();

  handleExit = () => {
    this.setState({ page: 0, pageSize: 5, items: [], allItems: [], total: 0, loading: true });
  };

  render() {
    const { loading, items, error } = this.state;
    const { opened, classes, group, onComplete } = this.props;

    return (
      <Dialog
        open={opened}
        disableEscapeKeyDown
        TransitionProps={{ onEnter: this.handleEnter }}
        onExit={this.handleExit}
      >
        {loading && <LinearProgress color='secondary' />}

        <DialogTitle>{this.renderSearch()}</DialogTitle>
        <DialogContent className={classes.content}>
          {!items.length && (
            <Fragment>
              <Divider />

              <CardContent>
                <Typography variant='subtitle1'>Nenhum imóvel encontrado</Typography>
              </CardContent>
            </Fragment>
          )}

          {!!error && (
            <Fragment>
              <Divider />

              <CardContent>
                <ErrorMessage error={error} tryAgain={this.loadData} />
              </CardContent>
            </Fragment>
          )}

          {!!group &&
            items.map(property => (
              <PropertyListItem
                key={property.id}
                property={property}
                forAddGroupId={group.id}
                forAddChecked={property.added}
                onAddChange={this.handleAddChange}
              />
            ))}
        </DialogContent>
        <DialogActions className={classes.actions}>
          {this.renderTablePagination()}
          <Button color='secondary' onClick={onComplete}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
