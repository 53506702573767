import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormValidation from '@react-form-fields/material-ui/components/FormValidation';
import { FormComponent, IStateForm } from 'components/Abstract/Form';
import Toast from 'components/Shared/Toast';
import IAddress from 'interfaces/models/address';
import bindComponent from 'rxjs-operators/bindComponent';
import addressService, { IAddressForm } from 'services/address';

import AddressForm from '..';

interface IState extends IStateForm<IAddress> {
  opened: boolean;
  loading: boolean;
  save: IAddressForm['save'];
}

interface IProps {}

export default class AddressFormDialog extends FormComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { ...this.state, opened: false, loading: false };
  }

  componentDidMount() {
    addressService
      .shouldOpenFormDialog()
      .pipe(bindComponent(this))
      .subscribe(({ opened, address, save }) => {
        this.setState({ opened, save, model: { ...(address || {}) } });
      });
  }

  updateModelAddress = (model: IAddress) => {
    this.setState({ model });
  };

  onCancel = () => {
    addressService.closeFormDialog();
  };

  onSubmit = (isValid: boolean) => {
    if (!isValid) return;

    this.setState({ loading: true });

    this.state
      .save(this.state.model as IAddress)
      .pipe(bindComponent(this))
      .subscribe(
        address => {
          this.setState({ loading: false });
          addressService.closeFormDialog(address);
        },
        err => {
          this.setState({ loading: false });
          Toast.error(err);
        }
      );
  };

  render() {
    const { opened, loading, model } = this.state;

    return (
      <Dialog disableEscapeKeyDown open={opened} onClose={this.resetForm}>
        {loading && <LinearProgress color='secondary' />}

        <FormValidation onSubmit={this.onSubmit} ref={this.bindForm}>
          <DialogTitle>{model.id ? 'Editar' : 'Novo'} Endereço</DialogTitle>

          <DialogContent>
            <AddressForm autoFocus={true} disabled={loading} value={model} onChange={this.updateModelAddress} />
          </DialogContent>

          <DialogActions>
            <Button disabled={loading} onClick={this.onCancel}>
              Cancelar
            </Button>
            <Button color='secondary' type='submit' disabled={loading}>
              Salvar
            </Button>
          </DialogActions>
        </FormValidation>
      </Dialog>
    );
  }
}
