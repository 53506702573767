import { Component } from 'react';

import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Loading from 'components/Shared/Loading';
import Toast from 'components/Shared/Toast';
import { IStyledProps, WithStyles } from 'decorators/withStyles';
import IPropertyExtraBenefit from 'interfaces/models/propertyExtraBenefit';
import isEqual from 'lodash/isEqual';
import bindComponent from 'rxjs-operators/bindComponent';
import propertyService from 'services/property';

import BenefitListItem from './ListItem';
import AddressZoneFormDialog from '..';

interface IState {
  loading: boolean;
  benefits: IPropertyExtraBenefit[];
}

interface IProps extends IStyledProps {
  disabled?: boolean;
  model: AddressZoneFormDialog['state']['model'];
  updateModel: AddressZoneFormDialog['updateModel'];
}

@WithStyles({
  groupTitle: {
    marginTop: 25
  },
  badgeWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  badge: {
    fontSize: 14,
    borderRadius: 50,
    padding: '1px 8px',
    background: '#328ED2',
    color: 'white',
    marginLeft: 2
  },
  get badgeOlx() {
    return { ...this.badge, background: '#6E3ED0' };
  },
  get badgeImovelWeb() {
    return { ...this.badge, background: '#E75834' };
  }
})
export default class AddressZoneFormBenefits extends Component<IProps, IState> {
  private columns = [['ACESSO E ÁREA', 'TEM NO IMÓVEL', 'SEGURANÇA'], ['DIFERENCIAIS']];

  constructor(props: IProps) {
    super(props);
    this.state = { ...this.state, benefits: [], loading: true };
  }

  shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>) {
    if (!isEqual(nextState, this.state)) {
      return true;
    }

    if (
      nextProps.disabled !== this.props.disabled ||
      !isEqual(nextProps.model?.benefitsIds, this.props.model?.benefitsIds)
    ) {
      return true;
    }

    return false;
  }

  componentDidMount() {
    propertyService
      .benefits()
      .pipe(bindComponent(this))
      .subscribe({
        next: data => this.setState({ loading: false, benefits: data ?? [] }),
        error: err => Toast.error(err)
      });
  }

  onChange = (id: number, checked: boolean) => {
    this.props.updateModel(model => {
      model.benefitsIds = model.benefitsIds || [];
      const includes = model.benefitsIds.includes(id);

      if (checked) {
        if (includes) return;

        model.benefitsIds = [...model.benefitsIds, id];
        return;
      }

      if (!includes) return;

      model.benefitsIds = model.benefitsIds.filter(b => b !== id);
    })(checked);
  };

  render() {
    const { loading, benefits } = this.state;
    const { model, classes } = this.props;

    return (
      <CardContent>
        <div className={classes.badgeWrapper}>
          <div className={classes.badge}>Viva Real</div>
          <div className={classes.badgeOlx}>OLX</div>
          <div className={classes.badgeImovelWeb}>Imóvel Web</div>
        </div>

        {loading && <Loading />}

        {!loading && (
          <Grid container spacing={2}>
            {this.columns.map((groups, index) => (
              <Grid item xs={12} sm={6} key={index}>
                {groups.map(group => (
                  <div key={group}>
                    <Typography className={classes.groupTitle}>{group}</Typography>

                    {benefits
                      .filter(b => b.group === group)
                      .map(benefit => (
                        <BenefitListItem
                          key={benefit.id}
                          benefit={benefit}
                          checked={model.benefitsIds?.includes(benefit.id) ?? false}
                          onChange={this.onChange}
                        />
                      ))}
                  </div>
                ))}
              </Grid>
            ))}
          </Grid>
        )}
      </CardContent>
    );
  }
}
