import { Fragment, PureComponent } from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Toolbar from 'components/Layout/Toolbar';
import AddressFormDialog from 'components/Shared/AddressForm/Dialog';
import ErrorMessage from 'components/Shared/ErrorMessage';
import FabButton from 'components/Shared/FabButton';
import { IStyledProps, WithStyles } from 'decorators/withStyles';
import IAddressZone from 'interfaces/models/addressZone';
import MapMarkerPlusIcon from 'mdi-react/MapMarkerPlusIcon';
import bindComponent from 'rxjs-operators/bindComponent';
import addressZoneService from 'services/addressZone';

import ListItem from './ListItem';
import AddressZoneFormDialog from '../FormDialog';

interface IState {
  loading: boolean;
  items: IAddressZone[];
  error?: any;
}

interface IProps extends IStyledProps {}

@WithStyles({
  loader: {
    textAlign: 'center',
    padding: '25px 0 15px 0'
  }
})
export class AddressZoneListPage extends PureComponent<IProps, IState> {
  actions = [
    {
      icon: MapMarkerPlusIcon,
      onClick: () => addressZoneService.openForm()
    }
  ];

  constructor(props: IProps) {
    super(props);
    this.state = { loading: true, items: [] };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState({ loading: true, error: null });

    addressZoneService
      .list()
      .pipe(bindComponent(this))
      .subscribe({
        next: result => this.setState({ items: result ?? [], loading: false }),
        error: error => this.setState({ loading: false, error })
      });
  };

  render() {
    const { items, loading, error } = this.state;
    const { classes } = this.props;

    return (
      <Fragment>
        <Toolbar title='Endereços' />

        <FabButton actions={this.actions} />

        <AddressZoneFormDialog />
        <AddressFormDialog />

        {loading && (
          <Card>
            <CardContent>
              <div className={classes.loader}>
                <CircularProgress color='secondary' />
              </div>
            </CardContent>
          </Card>
        )}

        {!loading && !!error && (
          <Card>
            <CardContent>
              <ErrorMessage error={error} tryAgain={this.loadData} />
            </CardContent>
          </Card>
        )}

        {!loading && !error && items.map(data => <ListItem key={data.id} data={data} />)}
      </Fragment>
    );
  }
}
