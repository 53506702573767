import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import ListItemComponent, { IStateListItem } from 'components/Abstract/ListItem';
import Alert from 'components/Shared/Alert';
import { IOption } from 'components/Shared/DropdownMenu';
import Toast from 'components/Shared/Toast';
import { IStyledProps, WithStyles } from 'decorators/withStyles';
import addressFormatter from 'formatters/address';
import IAddress from 'interfaces/models/address';
import DeleteIcon from 'mdi-react/DeleteIcon';
import EditIcon from 'mdi-react/EditIcon';
import bindComponent from 'rxjs-operators/bindComponent';
import addressZoneService from 'services/addressZone';

interface IProps extends IStyledProps {
  data: IAddress;
}

@WithStyles({
  container: {
    margin: '0 -23px',
    padding: '6px 20px'
  },
  divider: {
    margin: '10px -23px 5px -23px'
  }
})
export default class Address extends ListItemComponent<IProps, IStateListItem> {
  options: IOption[] = [
    { text: 'Editar', icon: EditIcon, handler: () => this.handleEditAddress() },
    {
      text: 'Excluir',
      icon: DeleteIcon,
      handler: () => this.handleRemoveAddress()
    }
  ];

  handleEditAddress = () => {
    const { data } = this.props;

    addressZoneService
      .openFormAddress(data.zoneId, data)
      .pipe(bindComponent(this))
      .subscribe({
        next: () => null,
        error: err => Toast.error(err)
      });
  };

  handleRemoveAddress = async () => {
    const { data } = this.props;

    const isOK = await Alert.confirm('Deseja realmente apagar o endereço?');
    if (!isOK) return;

    this.setState({ loading: true });

    addressZoneService
      .removeAddress(data.zoneId, data.id)
      .pipe(bindComponent(this))
      .subscribe({
        next: () => null,
        error: error => this.setState({ loading: false, error })
      });
  };

  render() {
    const { data, classes } = this.props;

    return (
      <div className={classes.container}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={true}>
            {addressFormatter(data)}
          </Grid>
          {this.renderSideMenu(this.options)}
        </Grid>

        <Divider className={classes.divider} />
      </div>
    );
  }
}
