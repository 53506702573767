import './assets/global.css';

import { PureComponent } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import FormFieldsContext from '@react-form-fields/material-ui/components/Context';
import { theme } from 'assets/theme';
import Dialogs from 'components/Dialogs';
import Pages from 'components/Pages';
import Alert from 'components/Shared/Alert';
import Loader from 'components/Shared/Loader';
import Toast from 'components/Shared/Toast';
import fieldConfig from 'fieldConfig';

class App extends PureComponent {
  formFieldConfig = fieldConfig;

  render() {
    return (
      <ThemeProvider theme={theme}>
        <FormFieldsContext config={this.formFieldConfig}>
          <CssBaseline />
          <Dialogs />

          <Loader />

          <Alert.Global />
          <Toast.Global />

          <Pages />
        </FormFieldsContext>
      </ThemeProvider>
    );
  }
}

export default App;
