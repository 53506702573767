import { Fragment, Component } from 'react';

import CustomMessage from '@react-form-fields/core/components/CustomMessage';
import FieldHidden from '@react-form-fields/material-ui/components/Hidden';
import ImageReader, { IImageReaderResult } from 'components/Shared/ImageSelector/ImageReader';
import { WithStyles, IStyledProps } from 'decorators/withStyles';
import { arrayMove, SortEnd } from 'react-sortable-hoc';

import Container from './Container';

interface IState {
  images: Array<string | IImageReaderResult>;
}

interface IProps extends IStyledProps {
  disabled?: boolean;
  insideDialog?: boolean;
  min?: number;
  max?: number;
  images: Array<string | IImageReaderResult>;
  onChange: (images: Array<string | IImageReaderResult>) => void;
}

@WithStyles(() => ({
  disableSelect: {
    userSelect: 'none',
    WebkitUserSelect: 'none',
    '*': {
      userSelect: 'none',
      WebkitUserSelect: 'none'
    }
  },
  noPadding: {
    paddingLeft: 0,
    paddingRight: 0
  },
  zIndex: {
    zIndex: 1400
  }
}))
export default class ImageUploader extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { images: props.images ?? [] };
  }

  onLoadFile = (result: IImageReaderResult) => {
    this.setState(
      state => ({ images: [...state.images, result] }),
      () => this.props.onChange(this.state.images)
    );
  };

  handleUploadSuccess = (image: IImageReaderResult, url: string) => {
    this.setState(
      state => ({ images: state.images.map((i: IImageReaderResult) => (i.key === image.key ? url : i)) }),
      () => this.props.onChange(this.state.images)
    );
  };

  handleDelete = (image: string | IImageReaderResult) => {
    this.setState(
      state => ({ images: state.images.filter(i => i !== image) }),
      () => this.props.onChange(this.state.images)
    );
  };

  onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    this.setState(
      state => ({ images: arrayMove(state.images, oldIndex, newIndex) }),
      () => this.props.onChange(this.state.images)
    );
  };

  render() {
    const { images } = this.state;
    const { insideDialog, classes, disabled, min, max } = this.props;

    return (
      <Fragment>
        <FieldHidden value={(images || []).length} validation={`required|integer|min:${min ?? 0}|max:${max ?? 99}`}>
          <CustomMessage rules='min'>Deve ter pelo menos 1 foto</CustomMessage>
          <CustomMessage rules='max'>Deve ter pelo no máximo 32 fotos</CustomMessage>
        </FieldHidden>

        <ImageReader multiple disabled={disabled} droppable={true} onLoad={this.onLoadFile} />

        <div className={classes.disableSelect}>
          {!!(images || []).length && (
            <Container
              disabled={disabled}
              images={images}
              helperClass={classes.zIndex}
              insideDialog={insideDialog}
              onUploadSuccess={this.handleUploadSuccess}
              onDelete={this.handleDelete}
              onSortEnd={this.onSortEnd}
              axis='xy'
              pressDelay={200}
            />
          )}
        </div>
      </Fragment>
    );
  }
}
