import IUserToken from 'interfaces/tokens/userToken';
import { Observable, BehaviorSubject, catchError, of, shareReplay } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import apiService, { ApiService } from './api';
import tokenService, { TokenService } from './token';

export class AuthService {
  private user$: Observable<IUserToken>;
  private openLogin$: BehaviorSubject<boolean>;
  private openChangePassword$: BehaviorSubject<boolean>;

  constructor(private api: ApiService, private tokenService: TokenService) {
    this.openLogin$ = new BehaviorSubject(false);
    this.openChangePassword$ = new BehaviorSubject(false);

    this.user$ = this.tokenService.getToken().pipe(
      map(token => {
        if (!token) return null;

        const user = this.tokenService.decode<IUserToken>(token);
        if (!user) return null;

        user.fullName = `${user.firstName} ${user.lastName || ''}`.trim();

        return user;
      }),
      catchError(() => {
        return of(null);
      }),
      shareReplay(1)
    );
  }

  public openLogin(): void {
    this.openLogin$.next(true);
  }

  public shouldOpenLogin(): Observable<boolean> {
    return this.openLogin$.asObservable();
  }

  public login(email: string, password: string): Observable<void> {
    return this.api.post('/auth/login', { email, password }).pipe(tap(() => this.openLogin$.next(false)));
  }

  public logout(): Observable<void> {
    return this.tokenService.clearToken();
  }

  public sendResetPassword(email: string): Observable<void> {
    return this.api.post('/auth/send-reset', { email });
  }

  public resetPassword(token: string, password: string): Observable<void> {
    return this.api.post('/auth/reset-password', { token, password });
  }

  public openChangePassword(): void {
    this.openChangePassword$.next(true);
  }

  public closeChangePassword(): void {
    this.openChangePassword$.next(false);
  }

  public shouldOpenChangePassword(): Observable<boolean> {
    return this.openChangePassword$.asObservable();
  }

  public changePassword(currentPassword: string, newPassword: string): Observable<void> {
    return this.api.post('/auth/change-password', {
      currentPassword,
      newPassword
    });
  }

  public getUser(): Observable<IUserToken> {
    return this.user$;
  }

  public canAccess(...roles: string[]): Observable<boolean> {
    return this.getUser().pipe(
      map(user => {
        if (!user) return false;

        if (!roles || roles.length === 0) return true;
        if (user.roles.includes('sysAdmin') || user.roles.includes('admin')) return true;

        return roles.some(r => user.roles.includes(r));
      })
    );
  }

  public isAuthenticated(): Observable<boolean> {
    return this.getUser().pipe(map(user => !!user));
  }
}

const authService = new AuthService(apiService, tokenService);
export default authService;
