import { Fragment } from 'react';
import * as React from 'react';

import Typography from '@material-ui/core/Typography';
import IAddress from 'interfaces/models/address';

import zipcodeFormatter from './zipcode';

export default function addressFormatter(address: IAddress): React.ReactNode {
  const { state, city, neighborhood, street, number, complement, zipcode } = address;

  return (
    <Fragment>
      <Typography variant='body1'>{`${street}, ${`${number} ${complement || ''}`.trim()}`}</Typography>
      <Typography variant='body2'>{`${neighborhood}, ${city} - ${state}, ${zipcodeFormatter(zipcode)}`}</Typography>
    </Fragment>
  );
}

export function addressFormatterText(address: IAddress): string {
  const { state, city, neighborhood, street, number, complement, zipcode } = address;

  return `${street}, ${`${number} ${
    complement || ''
  }`.trim()} - ${neighborhood}, ${city} - ${state}, ${zipcodeFormatter(zipcode)}`;
}
