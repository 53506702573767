import { PureComponent } from 'react';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { WithStyles } from 'decorators/withStyles';
import { fromEvent, sampleTime, map, filter } from 'rxjs';
import bindComponent from 'rxjs-operators/bindComponent';

interface IState {
  active: string;
  headers: {
    key: string;
    title: string;
    offsetTop: () => number;
    onClick: () => void;
  }[];
}

interface IProps {
  classes?: any;
}

@WithStyles(theme => ({
  container: {
    position: 'sticky',
    top: 80
  },
  link: {
    cursor: 'pointer',
    fontSize: 14,
    opacity: 0.7,
    margin: '8px 0px',
    padding: '4px 0 4px 8px',
    borderLeft: '3px solid transparent'
  },
  linkActive: {
    opacity: 1,
    borderColor: theme.palette.secondary.main
  },
  button: {
    marginTop: 30
  }
}))
export default class TableOfContents extends PureComponent<IProps, IState> {
  main: HTMLDivElement;

  constructor(props: IProps) {
    super(props);
    this.state = { active: 'header-0', headers: [] };
  }

  componentDidMount() {
    this.main = document.getElementById('main-content') as HTMLDivElement;

    const headers: IState['headers'] = [];

    document.querySelectorAll<HTMLHeadingElement>('#form-content h6').forEach((header, key) => {
      headers.push({
        key: `header-${key}`,
        title: header.textContent,
        offsetTop: () => header.offsetTop - 100,
        onClick: () => {
          this.main.scrollTo({ behavior: 'smooth', top: header.offsetTop - 100 });
        }
      });
    });

    const reversedHeaders = headers.concat().reverse();
    this.setState({ headers });

    fromEvent(this.main, 'scroll')
      .pipe(
        bindComponent(this),
        sampleTime(300),
        map(() => reversedHeaders.find(header => header.offsetTop() <= this.main.scrollTop)?.key),
        filter(active => active !== this.state.active)
      )
      .subscribe(active => this.setState({ active }));
  }

  render() {
    const { headers, active } = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        {headers?.map(header => (
          <div key={header.key}>
            <Typography
              onClick={header.onClick}
              className={`${classes.link} ${active === header.key ? classes.linkActive : ''}`}
            >
              {header.title}
            </Typography>
          </div>
        ))}

        <div className={classes.button}>
          <Button type='submit' fullWidth variant='contained' color='secondary'>
            Salvar
          </Button>
        </div>
      </div>
    );
  }
}
