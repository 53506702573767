import { Component } from 'react';

import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import FieldText from '@react-form-fields/material-ui/components/Text';
import ImageUploader from 'components/Shared/ImageUploader';

import PropertyFormPage from '..';

interface IProps {
  disabled?: boolean;
  model: PropertyFormPage['state']['model'];
  updateModel: PropertyFormPage['updateModel'];
}

export default class PropertyFormImage extends Component<IProps, {}> {
  render() {
    const { model, updateModel, disabled } = this.props;

    return (
      <CardContent>
        <Typography variant='h6' gutterBottom>
          Fotos e Vídeo
        </Typography>

        <FieldText
          label='Vídeo'
          value={model.video}
          disabled={disabled}
          validation='string|url|max:500'
          placeholder='Link para o vídeo'
          onChange={updateModel((m, v) => (m.video = v))}
        />

        <ImageUploader
          disabled={disabled}
          images={model.images}
          min={1}
          max={32}
          onChange={updateModel((model, value) => (model.images = value))}
        />
      </CardContent>
    );
  }
}
