import { Fragment } from 'react';

import { enRoles } from 'interfaces/models/user';
import { Route, RouteProps } from 'react-router-dom';
import { tap } from 'rxjs';
import bindComponent from 'rxjs-operators/bindComponent';
import authService from 'services/auth';

import PermissionHide from '../PermissionHide';

interface IProps extends RouteProps {
  role: enRoles | undefined | null;
}

export default class PermissionRoute extends Route<IProps> {
  constructor(props: IProps) {
    super(props);
    this.state = { isAuthenticated: false };
  }

  componentDidMount() {
    super.componentDidMount && super.componentDidMount();

    authService
      .isAuthenticated()
      .pipe(
        bindComponent(this),
        tap(isAuthenticated => {
          if (isAuthenticated) return;
          authService.openLogin();
        })
      )
      .subscribe(isAuthenticated => this.setState({ isAuthenticated }));
  }

  render() {
    const { isAuthenticated } = this.state;

    if (!isAuthenticated) {
      return null;
    }

    return (
      <Fragment>
        <PermissionHide role={this.props.role}>{super.render()}</PermissionHide>

        <PermissionHide inverse role={this.props.role}>
          <p>Não encontrado</p>
        </PermissionHide>
      </Fragment>
    );
  }
}
