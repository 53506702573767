import { Fragment, PureComponent } from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Toolbar from 'components/Layout/Toolbar';
import ErrorMessage from 'components/Shared/ErrorMessage';
import FabButton from 'components/Shared/FabButton';
import Toast from 'components/Shared/Toast';
import { IStyledProps, WithStyles } from 'decorators/withStyles';
import IOlxGroup from 'interfaces/models/olxGroup';
import PlusIcon from 'mdi-react/PlusIcon';
import bindComponent from 'rxjs-operators/bindComponent';
import { loader } from 'rxjs-operators/loader';
import olxService from 'services/olx';

import GroupItem from './GroupItem';
import PropertyAddDialog from './PropertyAdd';

interface IState {
  groups: IOlxGroup[];
  currentGroup?: IOlxGroup;
  loading: boolean;
  error?: any;
}

interface IProps extends IStyledProps {}

@WithStyles({
  loader: {
    textAlign: 'center',
    padding: '25px 0 15px 0'
  }
})
export default class OlxGroupPage extends PureComponent<IProps, IState> {
  actions = [
    {
      icon: PlusIcon,
      onClick: () => this.handleCreate()
    }
  ];

  constructor(props: IProps) {
    super(props);
    this.state = { loading: true, groups: [] };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState({ loading: true, error: null });

    olxService
      .list()
      .pipe(bindComponent(this))
      .subscribe({
        next: data => this.setState({ loading: false, groups: data ?? [] }),
        error: error => this.setState({ loading: false, error })
      });
  };

  handleCreate = () => {
    olxService
      .create()
      .pipe(loader(), bindComponent(this))
      .subscribe({
        next: () => Toast.show('Grupo criado'),
        error: err => Toast.error(err)
      });
  };

  handleAddProperty = (currentGroup: IOlxGroup) => {
    this.setState({ currentGroup });
  };

  handleAddPropertyCompleted = () => {
    this.setState({ currentGroup: null });
  };

  render() {
    const { groups, loading, error, currentGroup } = this.state;
    const { classes } = this.props;

    return (
      <Fragment>
        <Toolbar title='Anúncios Olx' />

        <FabButton actions={this.actions} />
        <PropertyAddDialog opened={!!currentGroup} group={currentGroup} onComplete={this.handleAddPropertyCompleted} />

        {loading && (
          <Card>
            <CardContent>
              <div className={classes.loader}>
                <CircularProgress color='secondary' />
              </div>
            </CardContent>
          </Card>
        )}

        {!loading && !!error && (
          <Card>
            <CardContent>
              <ErrorMessage error={error} tryAgain={this.loadData} />
            </CardContent>
          </Card>
        )}

        {!loading &&
          !error &&
          groups.map(data => <GroupItem key={data.id} group={data} onAddProperty={this.handleAddProperty} />)}
      </Fragment>
    );
  }
}
