import { Component } from 'react';

import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { IImageReaderResult } from 'components/Shared/ImageSelector/ImageReader';
import { IStyledProps, WithStyles } from 'decorators/withStyles';
import { SortableContainer } from 'react-sortable-hoc';

import Image from '../Image';

interface IProps extends IStyledProps {
  insideDialog: boolean;
  disabled: boolean;
  images: Array<string | IImageReaderResult>;
  onUploadSuccess: (data: IImageReaderResult, url: string) => void;
  onDelete: (data: string | IImageReaderResult) => void;
}

@WithStyles(theme => ({
  card: {
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: 10
  },
  container: {
    '& > div:first-child': {
      position: 'relative'
    },
    '& > div:first-child:before': {
      content: '"capa"',
      borderBottom: '3px solid ' + theme.palette.secondary.main,
      display: 'block',
      position: 'absolute',
      top: -14,
      left: 4,
      right: 4,
      textAlign: 'center',
      fontSize: 11,
      textTransform: 'uppercase',
      color: theme.palette.secondary.main,
      fontWeight: 'bold',
      letterSpacing: 1
    }
  }
}))
class Container extends Component<IProps, {}> {
  render() {
    const { images, onUploadSuccess, onDelete, classes, insideDialog, disabled } = this.props;

    return (
      <CardContent className={classes.card}>
        <Grid container spacing={1} wrap='wrap' className={classes.container}>
          {images.map((image, index) => (
            <Image
              insideDialog={insideDialog}
              disabled={disabled}
              key={typeof image === 'string' ? image : image.key}
              data={image}
              onUploadSuccess={onUploadSuccess}
              onDelete={onDelete}
              index={index}
            />
          ))}
        </Grid>
      </CardContent>
    );
  }
}

export default SortableContainer(Container);
