import { SyntheticEvent } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import FieldCheckbox from '@react-form-fields/material-ui/components/Checkbox';
import FormValidation from '@react-form-fields/material-ui/components/FormValidation';
import FieldText from '@react-form-fields/material-ui/components/Text';
import { FormComponent, IStateForm } from 'components/Abstract/Form';
import Alert from 'components/Shared/Alert';
import ImageUploader from 'components/Shared/ImageUploader';
import Toast from 'components/Shared/Toast';
import { IStyledProps, WithStyles } from 'decorators/withStyles';
import textCounter from 'helpers/textCounter';
import IAddressZone, { IAddressZoneForm } from 'interfaces/models/addressZone';
import bindComponent from 'rxjs-operators/bindComponent';
import addressZoneService from 'services/addressZone';

import AddressZoneFormBenefits from './Benefits';

interface IState extends IStateForm<IAddressZoneForm> {
  saving: boolean;
  opened: boolean;
  tab: number;
}

@WithStyles({
  content: {
    width: 1200,
    height: 500,
    maxWidth: 'calc(100vw - 20px)'
  }
})
export default class AddressZoneFormDialog extends FormComponent<IStyledProps, IState> {
  constructor(props: {}) {
    super(props);
    this.state = { ...this.state, saving: false, opened: false, tab: 0 };
  }

  get isEdit(): boolean {
    return !!this.state.model.id;
  }

  componentDidMount() {
    addressZoneService
      .shouldOpenForm()
      .pipe(bindComponent(this))
      .subscribe({
        next: ({ opened, model }) =>
          this.setState({
            opened,
            model: {
              ...(model || {}),
              images: model?.images ?? [],
              benefitsIds: model?.benefits?.map(b => b.id) ?? []
            },
            tab: 0
          }),
        error: err => Toast.error(err)
      });
  }

  handleChangeTab = (e: SyntheticEvent, tab: number) => {
    this.setState({ tab });
  };

  handleExit = () => {
    this.resetForm();
  };

  onSubmit = (isValid: boolean) => {
    if (!isValid) return;

    const { model } = this.state;

    if (model.images.some(i => typeof i !== 'string')) {
      Alert.show('Existem imagens pendentes de envio, aguarde o término.');
      return;
    }

    this.setState({ saving: true });

    addressZoneService
      .save(model as IAddressZone)
      .pipe(bindComponent(this))
      .subscribe({
        next: result => {
          Toast.show(this.isEdit ? 'Grupo de endereços salvo' : 'Grupo de endereços criado');
          this.setState({ saving: false });
          addressZoneService.closeForm(result);
        },
        error: err => {
          Toast.error(err);
          this.setState({ saving: false });
        }
      });
  };

  onCancel = () => {
    addressZoneService.closeForm();
  };

  render() {
    const { model, saving: loading, opened, tab } = this.state;
    const { classes } = this.props;

    return (
      <Dialog maxWidth='lg' open={opened} disableEscapeKeyDown onClose={this.handleExit}>
        {loading && <LinearProgress color='secondary' />}

        <FormValidation onSubmit={this.onSubmit} ref={this.bindForm}>
          <DialogTitle>{this.isEdit ? 'Editar' : 'Novo'} Grupo de Endereços</DialogTitle>

          <Tabs value={tab} onChange={this.handleChangeTab} indicatorColor='primary' scrollButtons='on'>
            <Tab label='Informações' />
            <Tab label='Beneficios' />
          </Tabs>
          <Divider />

          <DialogContent className={classes.content}>
            <TabPanel current={tab} index={0}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <FieldText
                    label='Nome'
                    disabled={loading}
                    value={model.name}
                    validation='required|min:3|max:150'
                    helperText={textCounter(model.name, 150)}
                    onChange={this.updateModel((model, v) => (model.name = v))}
                  />

                  <FieldCheckbox
                    disableLabelMargin
                    label='Site'
                    helperText='Usado apenas para os filtros de pesquisa do site'
                    disabled={loading}
                    checked={model.isCondon}
                    onChange={this.updateModel(m => (m.isCondon = !m.isCondon))}
                  />

                  <FieldText
                    label='Descrição'
                    value={model.description}
                    disabled={loading}
                    multiline
                    validation='string|max:3000'
                    helperText={textCounter(model.description, 3000)}
                    onChange={this.updateModel((m, v) => (m.description = v))}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography variant='h6' gutterBottom>
                    Fotos
                  </Typography>

                  <ImageUploader
                    insideDialog
                    disabled={loading}
                    images={model.images}
                    onChange={this.updateModel((m, v) => (m.images = v))}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel current={tab} index={1}>
              <AddressZoneFormBenefits model={model} updateModel={this.updateModel} />
            </TabPanel>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onCancel}>Cancelar</Button>
            <Button color='secondary' variant='contained' type='submit' disabled={loading}>
              Salvar
            </Button>
          </DialogActions>
        </FormValidation>
      </Dialog>
    );
  }
}
function TabPanel(props: { index: number; current: number; children: React.ReactNode; className?: string }) {
  const { children, current, index, className } = props;

  const display = current !== index ? 'none' : 'block';

  return (
    <div className={className} style={{ display }}>
      {children}
    </div>
  );
}
