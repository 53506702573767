import { Fragment, Component } from 'react';

import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FieldSelect from '@react-form-fields/material-ui/components/Select';
import FieldSwitch from '@react-form-fields/material-ui/components/Switch';
import AddressForm from 'components/Shared/AddressForm';
import Toast from 'components/Shared/Toast';
import { IStyledProps, WithStyles } from 'decorators/withStyles';
import ISelectItem from 'interfaces/selectItem';
import isEqual from 'lodash/isEqual';
import AlertCircleIcon from 'mdi-react/AlertCircleIcon';
import bindComponent from 'rxjs-operators/bindComponent';
import addressZoneService from 'services/addressZone';

import PropertyFormPage from '..';

interface IState {
  loading: boolean;
  originalAddressZone?: number;
  zones: Array<ISelectItem & { benefitsIds: number[] }>;
}

interface IProps extends IStyledProps {
  disabled?: boolean;
  model: PropertyFormPage['state']['model'];
  updateModel: PropertyFormPage['updateModel'];
}

@WithStyles(theme => ({
  alertIcon: {
    color: theme.palette.error.main
  },
  switch: {
    marginTop: 35,
    marginLeft: 15
  }
}))
export default class PropertyFormAddress extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { loading: true, zones: [] };
  }

  componentDidMount() {
    addressZoneService
      .list()
      .pipe(bindComponent(this))
      .subscribe({
        next: data => {
          this.setState({
            loading: false,
            originalAddressZone: this.props.model.addressZoneId,
            zones:
              data?.map(zone => ({
                value: zone.id,
                label: zone.name,
                benefitsIds: zone.benefits?.map(b => b.id) ?? []
              })) ?? []
          });
        },
        error: err => Toast.error(err)
      });
  }

  shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>) {
    if (!isEqual(nextState, this.state)) {
      return true;
    }

    if (
      nextProps.disabled !== this.props.disabled ||
      nextProps.model?.addressZoneId !== this.props.model?.addressZoneId ||
      nextProps.model?.mainAddress !== this.props.model?.mainAddress
    ) {
      return true;
    }

    return false;
  }

  onChangeZone = (value: number) => {
    this.props.updateModel(model => {
      model.addressZoneId = value;

      if (model.id) return;
      model.benefitsIds = this.state.zones.find(z => z.value == value).benefitsIds;
    })(value);
  };

  render() {
    const { zones, loading, originalAddressZone } = this.state;
    const { model, updateModel, disabled, classes } = this.props;

    return (
      <Fragment>
        <CardContent>
          <Typography variant='h6' gutterBottom>
            Endereço
          </Typography>

          <Grid container>
            <Grid item xs={true} sm={6}>
              <FieldSelect
                label='Grupo de Endereços'
                value={model.addressZoneId}
                emptyOption='Selecione...'
                loading={loading}
                disabled={disabled}
                options={zones}
                onChange={this.onChangeZone}
              />
            </Grid>

            <Grid item xs={false}>
              <div className={classes.switch}>
                <FieldSwitch
                  label='Não duplicar'
                  disabled={disabled}
                  checked={model.disableDuplication}
                  onChange={updateModel(m => (m.disableDuplication = !m.disableDuplication))}
                />
              </div>
            </Grid>
          </Grid>

          {!!originalAddressZone && model.addressZoneId !== originalAddressZone && (
            <Grid container spacing={1} alignItems='center'>
              <Grid item xs={false}>
                <AlertCircleIcon className={classes.alertIcon} />
              </Grid>
              <Grid item xs={true}>
                <Typography>
                  Ao mudar o grupo de endereço, os imóveis (cópias) referentes a esses endereços serão deletados e serão
                  criados novos
                </Typography>
              </Grid>
            </Grid>
          )}
        </CardContent>

        <Divider />

        <CardContent>
          <AddressForm
            disabled={disabled}
            value={model.mainAddress}
            onChange={updateModel((m, v) => (m.mainAddress = v))}
          />
        </CardContent>
      </Fragment>
    );
  }
}
