import { PureComponent, SyntheticEvent } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { WithStyles } from 'decorators/withStyles';
import formatAcceptExchange from 'formatters/acceptExchange';
import addressFormatter from 'formatters/address';
import { formatDocument } from 'formatters/document';
import moneyFormat from 'formatters/money';
import { phoneFormatter } from 'formatters/phone';
import { formatRentalPeriod, formatTransactionType } from 'formatters/property';
import IProperty, { enTransactionType } from 'interfaces/models/property';
import { enRoles } from 'interfaces/models/user';
import bindComponent from 'rxjs-operators/bindComponent';
import authService from 'services/auth';

interface IState {
  tab: number;
  isAdmin: boolean;
}

interface IProps {
  opened: boolean;
  onClose: () => void;
  property: IProperty;
  classes?: any;
}

@WithStyles({
  subtitle: {
    fontWeight: 'normal',
    fontSize: 14,
    display: 'block'
  },
  content: {
    padding: 16
  },
  tabPanel: {},
  description: {
    whiteSpace: 'pre-line'
  }
})
export default class PropertyDetailsDialog extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { tab: 0, isAdmin: false };
  }

  componentDidMount(): void {
    authService
      .canAccess(enRoles.admin)
      .pipe(bindComponent(this))
      .subscribe({
        next: isAdmin => this.setState({ isAdmin })
      });
  }

  handleChangeTab = (e: SyntheticEvent, tab: number) => {
    this.setState({ tab });
  };

  render() {
    const { tab, isAdmin } = this.state;
    const { opened, property, onClose, classes } = this.props;

    return (
      <Dialog disableEscapeKeyDown open={opened} onClose={onClose}>
        <DialogTitle>
          {property?.title}
          <small className={classes.subtitle}>
            {property?.propertyType?.display} • {property?.locationCount} Endereços •{' '}
            {property?.transactionType !== enTransactionType.rent && <>Venda: {moneyFormat(property?.salesPrice)}</>}
            {property?.transactionType === enTransactionType.both && ' • '}
            {property?.transactionType !== enTransactionType.sale && <>Aluguel: {moneyFormat(property?.rentalPrice)}</>}
          </small>
        </DialogTitle>

        <Tabs value={tab} onChange={this.handleChangeTab} indicatorColor='primary' scrollButtons='on' centered>
          <Tab label='Informações Básicas' />
          <Tab label='Proprietário' disabled={!isAdmin} />
          <Tab label='Valores / Características' />
        </Tabs>

        <Divider />

        <DialogContent className={classes.content}>
          {!!property && (
            <>
              <TabPanel current={tab} index={0} className={classes.tabPanel}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant='caption'>Endereço</Typography>
                    {property.locations?.[0]?.address ? (
                      addressFormatter(property.locations?.[0]?.address)
                    ) : (
                      <Typography variant='body2'>
                        <i>Não informado</i>
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='caption'>Descrição</Typography>
                    <Typography variant='body2' className={classes.description}>
                      {property.description ?? <i>Não informado</i>}
                    </Typography>
                  </Grid>
                </Grid>
              </TabPanel>

              <TabPanel current={tab} index={1} className={classes.tabPanel}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant='caption'>Nome</Typography>
                    <Typography variant='body2'>{property.propertyOwner?.name ?? <i>Não informado</i>}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography variant='caption'>Documento</Typography>
                    <Typography variant='body2'>
                      {formatDocument(property.propertyOwner?.document) ?? <i>Não informado</i>}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography variant='caption'>Email</Typography>
                    <Typography variant='body2'>{property.propertyOwner?.email ?? <i>Não informado</i>}</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant='caption'>Telefones</Typography>
                    <Typography variant='body2'>
                      {property.propertyOwner?.phones?.length ? (
                        property.propertyOwner.phones.map(p => phoneFormatter(p)).join(' • ')
                      ) : (
                        <i>Não informado</i>
                      )}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant='caption'>Classificação</Typography>
                    <Typography variant='body2'>
                      <strong>{property.propertyOwner?.ownerType?.title ?? <i>Não informado</i>}</strong> -{''}
                      {property.propertyOwner?.ownerTypeReason ?? <i>Não informado</i>}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography variant='caption'>Permuta</Typography>
                    <Typography variant='body2'>{formatAcceptExchange(property)}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography variant='caption'>Interesse</Typography>
                    <Typography variant='body2'>
                      {property.propertyOwner?.acceptPropertyType?.display ?? <i>Não informado</i>}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant='caption'>Local de Interesse</Typography>
                    <Typography variant='body2'>
                      {property.propertyOwner?.acceptLocation ?? <i>Não informado</i>}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant='caption'>Observação</Typography>
                    <Typography variant='body2'>
                      {property.propertyOwner?.observation ?? <i>Não informado</i>}
                    </Typography>
                  </Grid>
                </Grid>
              </TabPanel>

              <TabPanel current={tab} index={2} className={classes.tabPanel}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant='caption'>Tipo de Negociação</Typography>
                    <Typography variant='body2'>{formatTransactionType(property.transactionType)}</Typography>
                  </Grid>

                  {property.transactionType !== enTransactionType.rent && (
                    <Grid item xs={12} sm={4}>
                      <Typography variant='caption'>Valor de Venda</Typography>
                      <Typography variant='body2'>{moneyFormat(property.salesPrice)}</Typography>
                    </Grid>
                  )}

                  {property.transactionType !== enTransactionType.sale && (
                    <>
                      <Grid item xs={12} sm={4}>
                        <Typography variant='caption'>Valor de Aluguel</Typography>
                        <Typography variant='body2'>{moneyFormat(property.rentalPrice)}</Typography>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Typography variant='caption'>Periodicidade</Typography>
                        <Typography variant='body2'>{formatRentalPeriod(property.rentalPeriod)}</Typography>
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} sm={4}>
                    <Typography variant='caption'>Condomínio / Mês</Typography>
                    <Typography variant='body2'>
                      {moneyFormat(property.propertyAdministrationFee) ?? <i>Não informado</i>}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Typography variant='caption'>IPTU / Ano</Typography>
                    <Typography variant='body2'>{moneyFormat(property.yearlyTax) ?? <i>Não informado</i>}</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant='caption'>Tipo do Imóvel</Typography>
                    <Typography variant='body2'>{property.propertyType?.display}</Typography>
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <Typography variant='caption'>Quartos</Typography>
                    <Typography variant='body2'>{property.bedrooms}</Typography>
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <Typography variant='caption'>Suites</Typography>
                    <Typography variant='body2'>{property.suites}</Typography>
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <Typography variant='caption'>Banheiros</Typography>
                    <Typography variant='body2'>{property.bathrooms}</Typography>
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <Typography variant='caption'>Vagas</Typography>
                    <Typography variant='body2'>{property.garage}</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant='caption'>Área útil </Typography>
                    <Typography variant='body2'>{property.livingArea}m²</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant='caption'>Área Total</Typography>
                    <Typography variant='body2'>{property.lotArea}m²</Typography>
                  </Grid>
                </Grid>
              </TabPanel>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Fechar</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

function TabPanel(props: { index: number; current: number; children: React.ReactNode; className: string }) {
  const { children, current, index, className } = props;

  if (current !== index) return null;

  return <div className={className}>{children}</div>;
}
