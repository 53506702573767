import { Fragment } from 'react';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import FormValidation from '@react-form-fields/material-ui/components/FormValidation';
import { FormComponent, IStateForm } from 'components/Abstract/Form';
import Toolbar from 'components/Layout/Toolbar';
import Alert from 'components/Shared/Alert';
import ErrorMessage from 'components/Shared/ErrorMessage';
import Toast from 'components/Shared/Toast';
import { IRouteProps } from 'decorators/withRouter';
import { IStyledProps, WithStyles } from 'decorators/withStyles';
import { IPropertyForm } from 'interfaces/models/property';
import { enRoles } from 'interfaces/models/user';
import { combineLatest } from 'rxjs';
import bindComponent from 'rxjs-operators/bindComponent';
import { loader } from 'rxjs-operators/loader';
import authService from 'services/auth';
import propertyService from 'services/property';

import PropertyFormAddress from './Address';
import PropertyFormBasic from './Basic';
import PropertyFormBenefits from './Benefits';
import PropertyFormImage from './Images';
import PropertyFormIntegrations from './Integrations';
import PropertyFormNegociation from './Negociation';
import PropertyFormOwner from './Owner';
import TableOfContents from './TableOfContents';
import PropertyFormType from './Type';

interface IState extends IStateForm<IPropertyForm> {
  isAdmin: boolean;
  loading: boolean;
  loadingError?: any;
}

interface IProps extends IStyledProps, IRouteProps {}

@WithStyles({
  actions: {
    textAlign: 'right',
    marginTop: 20
  },
  loader: {
    textAlign: 'center'
  }
})
export default class PropertyFormPage extends FormComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      ...this.state,
      loading: !!this.props.match.params.id,
      model: { images: [], propertyOwner: { phones: [''], acceptExchange: false } }
    };
  }

  UNSAFE_componentWillMount() {
    this.loadData();
  }

  loadData = () => {
    const id = this.props.match.params.id;
    if (!id) return;

    this.setState({ loading: true, loadingError: null });

    combineLatest([propertyService.get(id), authService.canAccess(enRoles.admin)])
      .pipe(bindComponent(this))
      .subscribe({
        next: ([model, isAdmin]) => {
          this.setState({
            isAdmin,
            model: {
              ...model,
              propertyOwner: !isAdmin
                ? null
                : {
                    ...(model.propertyOwner ?? {}),
                    phones: !model.propertyOwner?.phones?.length ? [''] : model.propertyOwner.phones,
                    acceptExchange: model.propertyOwner?.acceptExchange ?? false
                  },
              mainAddress: model.locations.find(l => l.main).address,
              benefitsIds: model.benefits.map(b => b.id)
            },
            loading: false
          });
        },
        error: loadingError => this.setState({ loadingError, loading: false })
      });
  };

  get isEdit() {
    return !!this.props.match.params.id;
  }

  onSubmit = (isValid: boolean) => {
    const { model } = this.state;

    if (!isValid) {
      Toast.error('Revise os campos');
      return;
    }

    if (model.images.some(i => typeof i !== 'string')) {
      Alert.show('Existem imagens pendentes de envio, aguarde o término.');
      return;
    }

    propertyService
      .save(model)
      .pipe(loader(), bindComponent(this))
      .subscribe({
        next: () => {
          Toast.show('Imóvel Salvo');
          this.props.history.push(this.props.match.params.olx ? '/olx' : '/imoveis');
        },
        error: err => Toast.error(err)
      });
  };

  render() {
    const { model, loading, loadingError, isAdmin } = this.state;
    const { classes } = this.props;

    return (
      <Fragment>
        <Toolbar title={`${this.isEdit ? 'Editar' : 'Novo'} Imóvel`} />

        {loading && (
          <Card>
            <CardContent className={classes.loader}>
              <CircularProgress color='secondary' />
            </CardContent>
          </Card>
        )}

        {!loading && !!loadingError && (
          <Card>
            <CardContent>
              <ErrorMessage error={loadingError} tryAgain={this.loadData} />
            </CardContent>
          </Card>
        )}

        {!loading && !loadingError && (
          <FormValidation onSubmit={this.onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={9} id='form-content'>
                <Card>
                  <PropertyFormBasic model={model} updateModel={this.updateModel} />
                </Card>

                {(!this.isEdit || isAdmin) && (
                  <Card>
                    <PropertyFormOwner model={model} updateModel={this.updateModel} />
                  </Card>
                )}

                <Card>
                  <PropertyFormNegociation model={model} updateModel={this.updateModel} />
                </Card>

                <Card>
                  <PropertyFormType model={model} updateModel={this.updateModel} />
                </Card>

                <Card>
                  <PropertyFormAddress model={model} updateModel={this.updateModel} />
                </Card>

                <Card>
                  <PropertyFormIntegrations model={model} updateModel={this.updateModel} />
                </Card>

                <Card>
                  <PropertyFormBenefits model={model} updateModel={this.updateModel} />
                </Card>

                <Card>
                  <PropertyFormImage model={model} updateModel={this.updateModel} />
                </Card>

                <Hidden lgUp>
                  <div className={classes.actions}>
                    <Button type='submit' variant='contained' color='secondary'>
                      Salvar
                    </Button>
                  </div>
                </Hidden>
              </Grid>
              <Hidden mdDown>
                <Grid item lg={3}>
                  <TableOfContents />
                </Grid>
              </Hidden>
            </Grid>
          </FormValidation>
        )}
      </Fragment>
    );
  }
}
