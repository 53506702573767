import { Fragment, PureComponent } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import DropdownMenu, { IOption } from 'components/Shared/DropdownMenu';
import OptionItem from 'components/Shared/DropdownMenu/OptionItem';
import ErrorMessageIcon from 'components/Shared/ErrorMessageIcon';

export interface IStateListItem {
  loading: boolean;
  error?: any;
}

export default abstract class ListItemComponent<
  P = {},
  S extends IStateListItem = IStateListItem
> extends PureComponent<P, S> {
  styleProgress = { padding: 12 };

  constructor(props: P) {
    super(props);
    this.state = {
      loading: false,
      error: null
    } as Readonly<S>;
  }

  handleDismisError = () => {
    this.setState({ error: null });
  };

  renderSideMenu = (options: IOption | IOption[]) => {
    const { loading, error } = this.state;

    return (
      <Fragment>
        {loading && (
          <div style={this.styleProgress}>
            <CircularProgress color='secondary' size={20} />
          </div>
        )}
        {!loading && error && <ErrorMessageIcon error={error} onDismiss={this.handleDismisError} />}
        {!loading && !error && this.renderSideMenuOptions(options)}
      </Fragment>
    );
  };

  renderSideMenuOptions = (options: IOption | IOption[]) => {
    if (!options) return null;

    if (Array.isArray(options)) {
      return (
        <DropdownMenu>
          {options.map(o => (
            <OptionItem key={o.text} {...o} text={o.text || ''} />
          ))}
        </DropdownMenu>
      );
    }

    return (
      <IconButton onClick={options.handler}>
        <options.icon />
      </IconButton>
    );
  };
}
