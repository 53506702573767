import { Theme } from '@material-ui/core/styles';
import withStyles, { StyleRules, StyleRulesCallback, WithStylesOptions } from '@material-ui/core/styles/withStyles';

type StyleRulesCallbackR<ClassKeys extends string = string> = StyleRulesCallback<Theme, any, ClassKeys>;

export function WithStyles(styles: StyleRules<any> | StyleRulesCallbackR, options?: WithStylesOptions<Theme>) {
  return function <T>(target: T): T {
    return withStyles(styles, options)(target as any) as any;
  };
}

export type AppStyle<Classkeys extends string = string> = StyleRules<Classkeys> | StyleRulesCallbackR<Classkeys>;

export type ClassesFrom<AppStyle> = {
  [key in AppStyle extends StyleRulesCallbackR ? keyof ReturnType<AppStyle> : keyof AppStyle]: string;
};

export interface IStyledProps<T = any> {
  classes?: ClassesFrom<T>;
}
