import { Fragment, PureComponent } from 'react';

import Grid from '@material-ui/core/Grid';
import Toolbar from 'components/Layout/Toolbar';

import Featured from './Graphs/Featured';
import Sync from './Graphs/Sync';
import Total from './Graphs/Total';

interface IState {}

interface IProps {
  classes?: any;
}

export default class DashboardPage extends PureComponent<IProps, IState> {
  constructor(props: {}) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Fragment>
        <Toolbar title='Dashboard' />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Total />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Featured />
          </Grid>
          <Grid item xs={12}>
            <Sync />
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}
