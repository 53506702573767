import { DragEvent, Fragment, PureComponent } from 'react';
import * as React from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconMessage from 'components/Shared/IconMessage';
import { WithStyles } from 'decorators/withStyles';
import uniqueId from 'lodash/uniqueId';
import FolderDownloadIcon from 'mdi-react/FolderDownloadIcon';
import FolderOpenIcon from 'mdi-react/FolderOpenIcon';

import Toast from '../Toast';

export interface IImageReaderResult {
  key: string;
  filename: string;
  base64: string;
  width: number;
  height: number;
}

interface IState {
  loading: number;
  draggingOver: boolean;
}

interface IProps {
  droppable?: boolean;
  className?: string;
  onLoad: (result: IImageReaderResult) => void;
  multiple?: boolean;
  disabled?: boolean;
  classes?: any;
}

@WithStyles({
  progress: {
    marginRight: 5
  },
  dropArea: {
    width: '100%',
    maxHeight: '100%',
    border: '2px dashed #00000029',
    borderRadius: '3px',
    textAlign: 'center',
    paddingBottom: 20,
    height: 180
  },
  dropAreaProgress: {
    marginTop: 60
  },
  dropAreaDragging: {
    background: '#fff10a1f'
  },
  dropAreaDisabled: {
    opacity: 0.6
  },
  dropAreaDraggingChildren: {
    pointerEvents: 'none'
  }
})
export default class ImageReader extends PureComponent<IProps, IState> {
  inputRef: React.RefObject<HTMLInputElement> = React.createRef();
  extensions = ['png', 'jpg', 'bmp'];

  constructor(props: IProps) {
    super(props);
    this.state = { loading: 0, draggingOver: false };
  }

  handleSelectImage = () => {
    this.inputRef.current.click();
  };

  onFileSelected = async () => {
    const { files } = this.inputRef.current;
    if (!files.length) return;

    this.setState({ loading: files.length });

    for (let i = 0; i < files.length; i++) {
      this.loadFile(files.item(i));
      if (!this.props.multiple) break;
    }

    this.inputRef.current.value = '';
  };

  onDropFile = async (event: DragEvent<any>) => {
    event.preventDefault();

    this.setState({ draggingOver: false });

    if (this.state.loading) return;

    const { files } = event.dataTransfer;
    this.setState({ loading: files.length });

    for (let i = 0; i < files.length; i++) {
      this.loadFile(files.item(i));
      if (!this.props.multiple) break;
    }
  };

  onDragIn = (event: DragEvent<any>) => this.onDragInOut(true, event);
  onDragOut = (event: DragEvent<any>) => this.onDragInOut(false, event);

  onDragInOut = (draggingOver: boolean, event: DragEvent<any>) => {
    event.preventDefault();

    if (this.state.loading) return;

    if (this.state.draggingOver === draggingOver) return;
    this.setState({ draggingOver });
  };

  loadFile = (file: File) => {
    if (!file) return;

    const regexp = new RegExp(`.(${this.extensions.join('|')})$`, 'gi');

    if (!regexp.test(file.name)) {
      Toast.error(`Falha no arquivo (${file.name}): Apenas imagens: ${this.extensions.join(', ')}`);
      this.setState(state => ({ loading: state.loading - 1 }));
      return;
    }

    const reader = new FileReader();

    reader.onload = (e: any) => this.getImageDimensions(file.name, e.target.result);
    reader.onerror = () => {
      Toast.error(`Não conseguimos carregar o arquivo (${file.name})`);
      this.setState(state => ({ loading: state.loading - 1 }));
    };

    reader.readAsDataURL(file);
  };

  getImageDimensions = (filename: string, url: string) => {
    const image = new Image();

    image.onload = () => {
      setTimeout(() => {
        this.setState(state => ({ loading: state.loading - 1 }));
        this.props.onLoad({
          key: uniqueId('img-'),
          filename,
          base64: url,
          width: image.width,
          height: image.height
        });
      }, 1000);
    };

    image.onerror = () => {
      Toast.error(`Não conseguimos carregar a imagem (${filename})`);
      this.setState(state => ({ loading: state.loading - 1 }));
    };

    image.src = url;
  };

  render() {
    const { droppable } = this.props;

    return droppable ? this.renderArea() : this.renderButton();
  }

  renderArea = () => {
    const { draggingOver, loading } = this.state;
    const { classes, className, disabled } = this.props;

    return (
      <div
        className={`${classes.dropArea} ${className || ''} ${disabled ? classes.dropAreaDisabled : null} ${
          draggingOver ? classes.dropAreaDragging : null
        }`}
        onDrop={!disabled ? this.onDropFile : null}
        onDragOver={!disabled ? this.onDragIn : null}
        onDragLeave={!disabled ? this.onDragOut : null}
      >
        {!!loading && <CircularProgress color='secondary' size={50} className={classes.dropAreaProgress} />}

        {!loading && (
          <Fragment>
            <div className={classes.dropAreaDraggingChildren}>
              <IconMessage
                icon={FolderDownloadIcon}
                message={disabled ? 'Desativado' : 'Arraste e solte a imagem aqui ou'}
              />
            </div>

            <div className={draggingOver ? classes.dropAreaDraggingChildren : null}>{this.renderButton()}</div>
          </Fragment>
        )}
      </div>
    );
  };

  renderButton = () => {
    const { loading } = this.state;
    const { classes, multiple, droppable, disabled } = this.props;

    return (
      <Fragment>
        <input
          type='file'
          ref={this.inputRef}
          className='hide'
          onChange={this.onFileSelected}
          multiple={multiple}
          disabled={!!loading || disabled}
          accept={`.${this.extensions.join(',.')}`}
        />

        <Button color='secondary' disabled={!!loading || disabled} onClick={this.handleSelectImage}>
          {loading ? <CircularProgress className={classes.progress} size={20} /> : <FolderOpenIcon />}
          {loading ? 'Carregando' : `${droppable ? 'Clique para ' : ''}Selecionar`}
        </Button>
      </Fragment>
    );
  };
}
