import { PureComponent } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { WithStyles } from 'decorators/withStyles';

interface IProps {
  classes?: any;
}

@WithStyles(theme => ({
  progress: {
    margin: theme.spacing(2)
  }
}))
export default class Loading extends PureComponent<IProps> {
  render() {
    const { classes } = this.props;

    return (
      <Grid container justifyContent='center'>
        <Grid item>
          <CircularProgress className={classes.progress} color='secondary' />
        </Grid>
      </Grid>
    );
  }
}
