import IOlxGroup from 'interfaces/models/olxGroup';
import { Observable, map } from 'rxjs';
import cache, { cacheClean } from 'rxjs-operators/cache';

import apiService from './api';

export class OlxService {
  list(): Observable<IOlxGroup[]> {
    return apiService.get<IOlxGroup[]>('/olx-group').pipe(
      map(groups => groups.map((g, i) => ({ ...g, name: `Grupo ${i + 1}` }))),
      cache('olx-group')
    );
  }

  create(): Observable<void> {
    return apiService.post('/olx-group').pipe(cacheClean('olx-group'));
  }

  remove(olxGroupId: number): Observable<void> {
    return apiService.delete(`/olx-group/${olxGroupId}`).pipe(cacheClean('olx-group'));
  }

  addToGroup(olxGroupId: number, propertyId: number): Observable<void> {
    return apiService.post(`/olx-group/${olxGroupId}/property/${propertyId}`).pipe(cacheClean('olx-group'));
  }

  removeFromGroup(olxGroupId: number, propertyId: number): Observable<void> {
    return apiService.delete(`/olx-group/${olxGroupId}/property/${propertyId}`).pipe(cacheClean('olx-group'));
  }
}

const olxService = new OlxService();
export default olxService;
