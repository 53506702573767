import { PureComponent } from 'react';
import * as React from 'react';

import Drawer, { IMenu } from 'components/Layout/Drawer';
import PermissionRoute from 'components/Shared/PermissionRoute';
import { WithStyles } from 'decorators/withStyles';
import { enRoles } from 'interfaces/models/user';
import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon';
import DomainIcon from 'mdi-react/DomainIcon';
import GoogleMapsIcon from 'mdi-react/GoogleMapsIcon';
import GroupIcon from 'mdi-react/GroupIcon';
import HomeCityIcon from 'mdi-react/HomeCityIcon';
import { Redirect, Route, Switch } from 'react-router-dom';

import AddressZonePage from './AddressZone';
import CompanyPage from './Company';
import DashboardPage from './Dashboard';
import OlxGroupPage from './OlxGroup';
import PropertyPage from './Property';
import UserPage from './User';

interface IProps {
  classes?: any;
}

export const ScrollTopContext = React.createContext<(top?: number) => void>(() => null);

@WithStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    width: '100vw',
    height: '100vh'
  },
  content: {
    backgroundColor: theme.palette.background.default,
    width: '100vw',
    height: '100vh',
    overflow: 'auto',
    padding: theme.variables.contentPadding,
    [theme.breakpoints.up('sm')]: {
      padding: theme.variables.contentPaddingUpSm
    }
  },
  contentWrapper: {
    margin: 'auto'
  }
}))
export default class AdminPage extends PureComponent<IProps, {}> {
  mainContent: React.RefObject<HTMLDivElement> = React.createRef();
  menu: IMenu[] = [
    // { path: '/', display: 'Dashboard', icon: ViewDashboardIcon },
    { path: '/imoveis', display: 'Imóveis', icon: HomeCityIcon },
    { path: '/olx', display: 'OLX', icon: GroupIcon },
    { path: '/enderecos', display: 'Endereços', icon: GoogleMapsIcon },
    { path: '/empresa', display: 'Empresa', role: enRoles.admin, icon: DomainIcon },
    { path: '/usuarios', display: 'Usuários', role: enRoles.admin, icon: AccountMultipleIcon }
  ];

  scrollTop = (top?: number) => {
    setTimeout(() => this.mainContent.current.scrollTo(0, top ?? 0), 100);
  };

  renderRedirect = () => <Redirect to='/imoveis' />;

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <ScrollTopContext.Provider value={this.scrollTop}>
          <Drawer menu={this.menu}>
            <main ref={this.mainContent} id='main-content' className={classes.content}>
              <div className={classes.contentWrapper}>
                <Switch>
                  <PermissionRoute path='/usuarios' role={enRoles.sysAdmin} component={UserPage} />
                  <Route path='/olx' component={OlxGroupPage} />
                  <PermissionRoute path='/empresa' role={enRoles.sysAdmin} component={CompanyPage} />
                  <Route path='/enderecos' component={AddressZonePage} />
                  <Route path='/imoveis' component={PropertyPage} />
                  <Route path='/dashboard' component={DashboardPage} />
                  <Route render={this.renderRedirect} />
                </Switch>
              </div>
            </main>
          </Drawer>
        </ScrollTopContext.Provider>
      </div>
    );
  }
}
