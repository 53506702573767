import { Component } from 'react';

import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CustomMessage from '@react-form-fields/core/components/CustomMessage';
import FieldSelect from '@react-form-fields/material-ui/components/Select';
import FieldText from '@react-form-fields/material-ui/components/Text';
import Toast from 'components/Shared/Toast';
import IPropertyType from 'interfaces/models/propertyType';
import ISelectItem from 'interfaces/selectItem';
import isEqual from 'lodash/isEqual';
import bindComponent from 'rxjs-operators/bindComponent';
import propertyService from 'services/property';

import PropertyFormPage from '..';

interface IState {
  loading: boolean;
  types: IPropertyType[];

  isResidencial: boolean;

  typesResidencial?: ISelectItem<string>[];
  typesFiltered?: ISelectItem<number>[];
}

interface IProps {
  disabled?: boolean;
  model: PropertyFormPage['state']['model'];
  updateModel: PropertyFormPage['updateModel'];
}

export default class PropertyFormType extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      ...this.state,
      isResidencial: true,
      typesFiltered: [],
      typesResidencial: [
        { value: 'true', label: 'Residencial' },
        { value: 'false', label: 'Comercial' }
      ],
      loading: true
    };
  }

  shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>) {
    if (!isEqual(nextState, this.state)) {
      return true;
    }

    if (
      nextProps.disabled !== this.props.disabled ||
      nextProps.model?.propertyTypeId !== this.props.model?.propertyTypeId ||
      nextProps.model?.bedrooms !== this.props.model?.bedrooms ||
      nextProps.model?.suites !== this.props.model?.suites ||
      nextProps.model?.bathrooms !== this.props.model?.bathrooms ||
      nextProps.model?.garage !== this.props.model?.garage ||
      nextProps.model?.livingArea !== this.props.model?.livingArea ||
      nextProps.model?.lotArea !== this.props.model?.lotArea
    ) {
      return true;
    }

    return false;
  }

  componentDidMount() {
    propertyService
      .types()
      .pipe(bindComponent(this))
      .subscribe({
        next: result => {
          this.setState({ types: result || [], loading: false });
          this.changeType(this.state.isResidencial ? 'true' : 'false');
        },
        error: err => Toast.error(err)
      });
  }

  changeType = (value: 'true' | 'false') => {
    const isResidencial = value === 'true';

    this.setState(
      {
        isResidencial,
        typesFiltered: this.state.types
          .filter(t => t.value.includes(isResidencial ? 'Residential' : 'Commercial'))
          .map(t => ({ value: t.id, label: t.display }))
      },
      () => {
        if (!this.state.types.length) return;

        const hasOptions = this.state.typesFiltered.some(t => t.value === this.props.model.propertyTypeId);
        if (hasOptions) return;

        this.props.updateModel((m, v) => (m.propertyTypeId = v))(null);
      }
    );
  };

  render() {
    const { loading, typesFiltered, typesResidencial, isResidencial } = this.state;
    const { model, updateModel, disabled } = this.props;

    return (
      <CardContent>
        <Typography variant='h6' gutterBottom>
          Características
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <FieldSelect
              label='Residencial / Comerical'
              value={isResidencial ? 'true' : 'false'}
              disabled={disabled}
              loading={loading}
              options={typesResidencial}
              onChange={this.changeType}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FieldSelect
              label='Tipo do Imóvel'
              value={model.propertyTypeId}
              disabled={disabled}
              loading={loading}
              options={typesFiltered}
              emptyOption='Selecine...'
              onChange={updateModel((m, v) => (m.propertyTypeId = v))}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6} sm={3} md={2}>
            <FieldText
              label='Quartos'
              value={model.bedrooms}
              disabled={disabled}
              type='number'
              validation='integer|min:0|max:20'
              onChange={updateModel((m, v) => (m.bedrooms = v))}
            />
          </Grid>

          <Grid item xs={6} sm={3} md={2}>
            <FieldText
              label='Suites'
              value={model.suites}
              disabled={disabled}
              type='number'
              validation='integer|min:0|max:20'
              onChange={updateModel((m, v) => (m.suites = v))}
            />
          </Grid>

          <Grid item xs={6} sm={3} md={2}>
            <FieldText
              label='Banheiros'
              value={model.bathrooms}
              disabled={disabled}
              type='number'
              validation='integer|min:0|max:20'
              onChange={updateModel((m, v) => (m.bathrooms = v))}
            />
          </Grid>

          <Grid item xs={6} sm={3} md={2}>
            <FieldText
              label='Vagas'
              value={model.garage}
              disabled={disabled}
              type='number'
              validation='integer|min:0|max:20'
              onChange={updateModel((m, v) => (m.garage = v))}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <FieldText
              label='Área útil'
              value={model.livingArea}
              disabled={disabled}
              type='number'
              validation='required|integer|min:0'
              onChange={updateModel((m, v) => (m.livingArea = v))}
              InputProps={{ endAdornment: <Typography>m²</Typography> }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <FieldText
              label='Área Total'
              value={model.lotArea}
              disabled={disabled}
              type='number'
              validation={`integer|min:${model.livingArea || 0}`}
              onChange={updateModel((m, v) => (m.lotArea = v))}
              InputProps={{ endAdornment: <Typography>m²</Typography> }}
            >
              <CustomMessage rules='min'>Deve ser maior ou igual a área útil</CustomMessage>
            </FieldText>
          </Grid>
        </Grid>
      </CardContent>
    );
  }
}
