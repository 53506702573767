import { Fragment, Component } from 'react';

import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import { FieldSwitch } from '@react-form-fields/material-ui';
import FieldSelect from '@react-form-fields/material-ui/components/Select';
import FieldText from '@react-form-fields/material-ui/components/Text';
import Toast from 'components/Shared/Toast';
import textCounter from 'helpers/textCounter';
import IPropertyType from 'interfaces/models/propertyType';
import ISelectItem from 'interfaces/selectItem';
import isEqual from 'lodash/isEqual';
import TrashCanIcon from 'mdi-react/TrashCanIcon';
import bindComponent from 'rxjs-operators/bindComponent';
import propertyService from 'services/property';

import PropertyFormPage from '..';

interface IState {
  loadingOwnerTypes: boolean;
  ownerTypes: ISelectItem<number>[];
  optionsValues: ISelectItem<string>[];

  loadingTypes: boolean;
  isResidencial: boolean;

  types: IPropertyType[];
  typesResidencial: ISelectItem<string>[];
  typesFiltered: ISelectItem<number>[];
}

interface IProps {
  disabled?: boolean;
  model: PropertyFormPage['state']['model'];
  updateModel: PropertyFormPage['updateModel'];
}

export default class PropertyFormOwner extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      ownerTypes: [],
      loadingOwnerTypes: true,
      loadingTypes: true,
      isResidencial: true,
      optionsValues: [
        { value: 'true', label: 'Maior Valor' },
        { value: 'false', label: 'Menor Valor' }
      ],
      types: [],
      typesFiltered: [],
      typesResidencial: [
        { value: 'true', label: 'Residencial' },
        { value: 'false', label: 'Comercial' }
      ]
    };
  }
  shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>) {
    if (!isEqual(nextState, this.state)) {
      return true;
    }

    if (
      nextProps.disabled !== this.props.disabled ||
      !isEqual(nextProps.model?.propertyOwner, this.props.model?.propertyOwner)
    ) {
      return true;
    }

    return false;
  }

  componentDidMount() {
    propertyService
      .ownerTypes()
      .pipe(bindComponent(this))
      .subscribe({
        next: result => {
          this.setState({
            ownerTypes: result?.map(t => ({ value: t.id, label: t.title })) ?? [],
            loadingOwnerTypes: false
          });
        },
        error: err => Toast.error(err)
      });

    propertyService
      .types()
      .pipe(bindComponent(this))
      .subscribe({
        next: result => {
          this.setState({ types: result || [], loadingTypes: false });
          this.changeType(this.state.isResidencial ? 'true' : 'false');
        },
        error: err => Toast.error(err)
      });
  }

  changeType = (value: 'true' | 'false') => {
    const isResidencial = value === 'true';

    this.setState(
      {
        isResidencial,
        typesFiltered: this.state.types
          .filter(t => t.value.includes(isResidencial ? 'Residential' : 'Commercial'))
          .map(t => ({ value: t.id, label: t.display }))
      },
      () => {
        if (!this.state.types.length) return;

        const hasOptions = this.state.typesFiltered.some(
          t => t.value === this.props.model.propertyOwner?.acceptPropertyTypeId
        );
        if (hasOptions) return;

        this.props.updateModel((m, v) => (m.propertyOwner.acceptPropertyTypeId = v))(null);
      }
    );
  };

  render() {
    const {
      ownerTypes,
      loadingOwnerTypes,
      loadingTypes,
      optionsValues,
      isResidencial,
      typesFiltered,
      typesResidencial
    } = this.state;
    const { model, disabled, updateModel } = this.props;

    return (
      <Fragment>
        <CardContent>
          <Typography variant='h6' gutterBottom>
            Proprietário
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <FieldText
                label='Nome'
                value={model.propertyOwner?.name}
                disabled={disabled}
                helperText={textCounter(model.propertyOwner?.name, 150)}
                validation='string|required|min:1|max:150'
                onChange={updateModel((m, v) => (m.propertyOwner.name = v))}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FieldText
                label='CPF/CNPJ'
                value={model.propertyOwner?.document}
                disabled={disabled}
                mask='document'
                validation='string|max:14'
                onChange={updateModel((m, v) => (m.propertyOwner.document = v))}
              />
            </Grid>

            <Grid item xs={12} sm={7}>
              <FieldText
                label='Endereço/Complemento'
                value={model.propertyOwner?.address}
                disabled={disabled}
                helperText={textCounter(model.propertyOwner?.address, 3000)}
                validation='string|max:3000'
                onChange={updateModel((m, v) => (m.propertyOwner.address = v))}
              />
            </Grid>

            <Grid item xs={12} sm={5}>
              {model.propertyOwner?.phones?.map((phone, index) => (
                <FieldText
                  key={index}
                  label={index === 0 ? 'Telefones' : null}
                  value={phone}
                  disabled={disabled}
                  margin={index === 0 ? 'normal' : 'dense'}
                  mask='phone'
                  validation='string|max:11'
                  InputProps={
                    model.propertyOwner.phones.length > 1
                      ? {
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                color='secondary'
                                onClick={this.props.updateModel(m => m.propertyOwner.phones.splice(index, 1))}
                              >
                                <TrashCanIcon />
                              </IconButton>
                            </InputAdornment>
                          )
                        }
                      : null
                  }
                  onChange={updateModel((m, v) => (m.propertyOwner.phones[index] = v))}
                />
              ))}
              <Button color='secondary' onClick={this.props.updateModel(m => m.propertyOwner.phones.push(''))}>
                Adicionar
              </Button>
            </Grid>
          </Grid>
        </CardContent>

        <Divider />

        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FieldSwitch
                label='Aceita Permuta'
                disabled={disabled}
                checked={model.propertyOwner?.acceptExchange}
                onChange={updateModel(m => (m.propertyOwner.acceptExchange = !m.propertyOwner.acceptExchange))}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <FieldSelect
                label='Menor ou Maior Valor'
                value={model.propertyOwner?.acceptGreatherPrice?.toString()}
                disabled={disabled}
                options={optionsValues}
                emptyOption='Selecione...'
                onChange={updateModel(
                  (m, v) => (m.propertyOwner.acceptGreatherPrice = v === 'true' ? true : v === 'false' ? false : null)
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <FieldSelect
                label='Residencial / Comerical'
                value={isResidencial ? 'true' : 'false'}
                disabled={disabled}
                loading={loadingTypes}
                options={typesResidencial}
                onChange={this.changeType}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <FieldSelect
                label='Tipo do Imóvel'
                value={model.propertyOwner?.acceptPropertyTypeId}
                disabled={disabled}
                loading={loadingTypes}
                options={typesFiltered}
                emptyOption='Selecine...'
                onChange={updateModel((m, v) => (m.propertyOwner.acceptPropertyTypeId = v))}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <FieldText
                label='Localização de interesse'
                value={model.propertyOwner?.acceptLocation}
                disabled={disabled}
                validation='string|max:1000'
                helperText={textCounter(model.propertyOwner?.acceptLocation, 1000)}
                onChange={updateModel((m, v) => (m.propertyOwner.acceptLocation = v))}
              />
            </Grid>
          </Grid>
        </CardContent>

        <Divider />

        <CardContent>
          <Grid container spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FieldSelect
                  label='Classificação'
                  value={model.propertyOwner?.ownerTypeId}
                  disabled={disabled}
                  loading={loadingOwnerTypes}
                  options={ownerTypes}
                  emptyOption='Selecine...'
                  onChange={updateModel((m, v) => (m.propertyOwner.ownerTypeId = v))}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FieldText
                  label='Motivo da Classificação'
                  value={model.propertyOwner?.ownerTypeReason}
                  disabled={disabled}
                  validation='string|max:500'
                  helperText={textCounter(model.propertyOwner?.ownerTypeReason, 500)}
                  onChange={updateModel((m, v) => (m.propertyOwner.ownerTypeReason = v))}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <FieldText
                label='Observação'
                value={model.propertyOwner?.observation}
                disabled={disabled}
                multiline
                validation='string|max:3000'
                helperText={textCounter(model.propertyOwner?.observation, 3000)}
                onChange={updateModel((m, v) => (m.propertyOwner.observation = v))}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Fragment>
    );
  }
}
