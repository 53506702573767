import { PureComponent } from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ErrorMessage from 'components/Shared/ErrorMessage';
import { IStyledProps, WithStyles } from 'decorators/withStyles';
import { IDashboardFeatured } from 'interfaces/models/dashboard';
import StarIcon from 'mdi-react/StarIcon';
import bindComponent from 'rxjs-operators/bindComponent';
import dashboardService from 'services/dashbord';

interface IState extends Partial<IDashboardFeatured> {
  loading: boolean;
  error?: any;
}

@WithStyles(theme => ({
  wrapper: {
    height: '100%'
  },
  icon: {
    marginRight: 10,
    verticalAlign: 'text-top',
    color: theme.variables.colors.featured
  },
  iconImovelWeb: {
    marginRight: 10,
    verticalAlign: 'text-top',
    color: theme.variables.colors.featuredImovelWeb
  },
  iconImovelWebSuper: {
    marginRight: 10,
    verticalAlign: 'text-top',
    color: theme.variables.colors.featuredImovelWebSuper
  },
  error: {
    color: theme.variables.colors.statusCanceled
  },
  label: {
    marginLeft: 35
  }
}))
export default class Featured extends PureComponent<IStyledProps, IState> {
  constructor(props: IStyledProps) {
    super(props);
    this.state = { loading: true };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState({ loading: true, error: null });

    dashboardService
      .featured()
      .pipe(bindComponent(this))
      .subscribe({
        next: data => this.setState({ loading: false, ...(data ?? {}) }),
        error: error => this.setState({ loading: false, error })
      });
  };

  render() {
    const { loading, error, current, limit, imovelWeb } = this.state;
    const { classes } = this.props;

    return (
      <Card className={classes.wrapper}>
        {loading && <LinearProgress variant='indeterminate' />}

        {!!error && (
          <CardContent>
            <ErrorMessage error={error} tryAgain={this.loadData} />
          </CardContent>
        )}

        {!error && (
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={4}>
                <Typography color='textSecondary'>Viva Real</Typography>
                <Tooltip title='Quando acima do limite os imóveis extras é marcado como normal'>
                  <Typography variant='h5' className={current > limit ? classes.error : null}>
                    <StarIcon className={classes.icon} />
                    {current}/{limit}
                  </Typography>
                </Tooltip>
                <Typography variant='body2' color='textSecondary' className={classes.label}>
                  Destaques
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} lg={8}>
                <Typography color='textSecondary'>Imóvel Web</Typography>

                <Grid container spacing={1}>
                  <Grid item xs={6} sm={12} lg={6}>
                    <Tooltip title='Quando acima do limite os imóveis extras é marcado como normal'>
                      <Typography
                        variant='h5'
                        className={imovelWeb?.featured > imovelWeb?.featuredLimit ? classes.error : null}
                      >
                        <StarIcon className={classes.iconImovelWeb} />
                        {imovelWeb?.featured}/{imovelWeb?.featuredLimit}
                      </Typography>
                    </Tooltip>
                    <Typography variant='body2' color='textSecondary' className={classes.label}>
                      Destaques
                    </Typography>
                  </Grid>

                  <Grid item xs={6} sm={12} lg={6}>
                    <Tooltip title='Quando acima do limite os imóveis extras é marcado como normal'>
                      <Typography
                        variant='h5'
                        className={imovelWeb?.superFeatured > imovelWeb?.superFeaturedLimit ? classes.error : null}
                      >
                        <StarIcon className={classes.iconImovelWebSuper} />
                        {imovelWeb?.superFeatured}/{imovelWeb?.superFeaturedLimit}
                      </Typography>
                    </Tooltip>
                    <Typography variant='body2' color='textSecondary' className={classes.label}>
                      Super Destaques
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        )}
      </Card>
    );
  }
}
