import { PureComponent } from 'react';

import FieldCheckbox from '@react-form-fields/material-ui/components/Checkbox';
import { IStyledProps, WithStyles } from 'decorators/withStyles';
import IPropertyExtraBenefit from 'interfaces/models/propertyExtraBenefit';

interface IProps extends IStyledProps {
  benefit: IPropertyExtraBenefit;
  checked: boolean;
  onChange: (id: number, checked: boolean) => void;
}

@WithStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0 6px',
    marginLeft: -6,
    borderRadius: 5,
    transition: '0.3s',
    backgroundColor: 'transparent',
    '& .MuiCheckbox-root': {
      paddingTop: 2,
      paddingBottom: 2,
      paddingRight: 7
    },
    '& .MuiFormControlLabel-root': {
      flex: 1
    },
    '&:hover': {
      backgroundColor: '#F0F0F0'
    }
  },
  badgeWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 0,
    justifyContent: 'flex-end'
  },
  badge: {
    fontSize: 11,
    borderRadius: 50,
    padding: 5,
    background: '#328ED2',
    color: 'white',
    marginLeft: 5
  },
  get badgeOlx() {
    return { ...this.badge, background: '#6E3ED0' };
  },
  get badgeImovelWeb() {
    return { ...this.badge, background: '#E75834' };
  }
})
export default class BenefitListItem extends PureComponent<IProps> {
  onChange = (checked: boolean) => {
    this.props.onChange(this.props.benefit.id, checked);
  };

  render() {
    const { benefit, checked, classes } = this.props;

    return (
      <div className={classes.wrapper}>
        <FieldCheckbox label={benefit.display} checked={checked} onChange={this.onChange} />
        <div className={classes.badgeWrapper}>
          {!!benefit.vivaReal && <div className={classes.badge} />}
          {!!benefit.olx && <div className={classes.badgeOlx} />}
          {!!benefit.imovelWeb && <div className={classes.badgeImovelWeb} />}
        </div>
      </div>
    );
  }
}
