import ConfigBuilder from '@react-form-fields/material-ui/config/builder';
import lang from '@react-form-fields/material-ui/lang/pt-br';

const fieldConfig = new ConfigBuilder()
  .fromLang(lang)
  .addMask(
    'integer',
    (value: string) => {
      return value;
    },
    value => {
      if (!value) return value;
      return Number(value) || Number((value || '').toString().substr(0, value.toString().length - 1)) || 0;
    }
  )
  .build();

export default fieldConfig;
