import { memo, useMemo } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import makeStyles from '@material-ui/core/styles/makeStyles';
import loaderService from 'services/loader';

import useObservable from '@eduzz/houston-hooks/useObservable';

const useStyle = makeStyles(theme => ({
  loader: {
    width: 70,
    height: 70,
    color: theme.palette.secondary.light
  },
  paper: {
    boxShadow: 'none',
    outline: 'none',
    backgroundColor: 'transparent'
  }
}));

const Loader = memo((props: Record<string, never>) => {
  const classes = useStyle(props);
  const [visible] = useObservable(() => loaderService.shouldShow(), []);

  const paperProps = useMemo(() => ({ className: classes.paper }), [classes.paper]);

  return (
    <Dialog open={visible || false} PaperProps={paperProps}>
      <CircularProgress className={classes.loader} size='large' color='inherit' />
    </Dialog>
  );
});

export default Loader;
