import IProperty from 'interfaces/models/property';

export default function formatAcceptExchange(property: IProperty) {
  if (property.propertyOwner?.acceptExchange === null && property.propertyOwner?.acceptExchange === undefined)
    return 'Não aceita permuta';

  if (!property.propertyOwner?.acceptExchange) {
    return 'Não aceita permuta';
  }

  if (property.propertyOwner.acceptGreatherPrice === null && property.propertyOwner.acceptGreatherPrice === undefined) {
    return 'Aceita permuta';
  }

  return `Aceita permuta de ${property.propertyOwner.acceptGreatherPrice ? 'Maior' : 'Menor'} Valor`;
}
