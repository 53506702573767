const cnpj = (value: string) => {
  if (!value) return value;

  const regexp = /^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2}).*/;
  const result = '$1.$2.$3/$4-$5';

  return value
    .replace(regexp, result)
    .replace(/[-.\\]$/, '')
    .replace(/[-.\\]$/, '')
    .replace(/[-.\\]$/, '');
};

const cpf = (value: string) => {
  if (!value) return value;

  const regexp = /^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2}).*/;
  const result = '$1.$2.$3-$4';

  return value
    .replace(regexp, result)
    .replace(/[-.\\]$/, '')
    .replace(/[-.\\]$/, '')
    .replace(/[-.\\]$/, '');
};

export const formatDocument = (value: string) => {
  if (!value) return value;
  return value.length > 11 ? cnpj(value) : cpf(value);
};
