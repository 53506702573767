import IAddress from 'interfaces/models/address';
import ISelectItem from 'interfaces/selectItem';
import { Observable, BehaviorSubject, Subject, from, of } from 'rxjs';

import states from './states';
import zipcodeProviders from './zipcodeProviders';

export interface IAddressForm {
  opened: boolean;
  address?: IAddress;
  save?: (address: IAddress) => Observable<IAddress>;
}

export class AddressService {
  private openFormDialog$ = new BehaviorSubject<IAddressForm>({
    opened: false
  });
  private formDialogResult$: Subject<IAddress>;

  getAddressByZipcode(zipcode: string): Observable<IAddress> {
    return from(zipcodeProviders.getResult(zipcode));
  }

  getStates(): Observable<ISelectItem<string>[]> {
    return of(states);
  }

  shouldOpenFormDialog(): Observable<IAddressForm> {
    return this.openFormDialog$.asObservable();
  }

  openFormDialog(address: IAddress, save: IAddressForm['save']): Observable<IAddress> {
    this.openFormDialog$.next({ opened: true, address, save });

    if (this.formDialogResult$) {
      this.formDialogResult$.next(null);
      this.formDialogResult$.complete();
    }

    this.formDialogResult$ = new Subject<IAddress>();
    return this.formDialogResult$.asObservable();
  }

  closeFormDialog(address?: IAddress) {
    this.openFormDialog$.next({ opened: false });
    this.formDialogResult$.next(address);
    this.formDialogResult$.complete();
    this.formDialogResult$ = null;
  }
}

const addressService = new AddressService();
export default addressService;
