import IAddress from 'interfaces/models/address';
import IAddressZone from 'interfaces/models/addressZone';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import cache, { cacheClean } from 'rxjs-operators/cache';

import addressService from './address';
import apiService from './api';

export class AddressZoneService {
  private openFormDialog$ = new BehaviorSubject<{
    opened: boolean;
    model?: IAddressZone;
  }>({ opened: false });
  private formDialogResult$: Subject<IAddressZone>;

  list(): Observable<IAddressZone[]> {
    return apiService.get('/address-zone').pipe(cache('address-zone'));
  }

  getAddresses(zoneId: number): Observable<IAddress[]> {
    return apiService.get(`/address-zone/${zoneId}/addresses`).pipe(cache(`address-zone-${zoneId}-addresses`));
  }

  save(model: IAddressZone): Observable<IAddressZone> {
    return apiService.post('/address-zone', model).pipe(cacheClean('address-zone'));
  }

  delete(id: number): Observable<void> {
    return apiService.delete(`/address-zone/${id}`).pipe(cacheClean('address-zone'));
  }

  shouldOpenForm(): Observable<{ opened: boolean; model?: IAddressZone }> {
    return this.openFormDialog$.asObservable();
  }

  openForm(addressZone?: IAddressZone): Observable<IAddressZone> {
    this.openFormDialog$.next({ opened: true, model: addressZone });

    if (this.formDialogResult$) {
      this.formDialogResult$.next(null);
      this.formDialogResult$.complete();
    }

    this.formDialogResult$ = new Subject();
    return this.formDialogResult$.asObservable();
  }

  closeForm(addressZone?: IAddressZone) {
    this.openFormDialog$.next({ opened: false });
    this.formDialogResult$.next(addressZone);
    this.formDialogResult$.complete();
    this.formDialogResult$ = null;
  }

  openFormAddress(zoneId: number, address?: IAddress): Observable<IAddress> {
    return addressService.openFormDialog(address, address =>
      this.saveAddress(zoneId, address).pipe(cacheClean(`address-zone-${zoneId}-addresses`))
    );
  }

  saveAddress(zoneId: number, address: IAddress): Observable<IAddress> {
    return apiService.post(`/address-zone/${zoneId}/addresses`, address);
  }

  removeAddress(zoneId: number, addressId: number): Observable<void> {
    return apiService
      .delete(`/address-zone/${zoneId}/addresses/${addressId}`)
      .pipe(cacheClean(`address-zone-${zoneId}-addresses`));
  }
}

const addressZoneService = new AddressZoneService();
export default addressZoneService;
