import IProperty, { enPropertyStatus, IPropertyForm, enPropertyFeaturedImovelWeb } from 'interfaces/models/property';
import IPropertyExtraBenefit from 'interfaces/models/propertyExtraBenefit';
import IPropertyOlxCategory from 'interfaces/models/propertyOlxCategory';
import IPropertyOwnerType from 'interfaces/models/propertyOwnerType';
import IPropertyType from 'interfaces/models/propertyType';
import { IPaginationParams, IPaginationResponse } from 'interfaces/pagination';
import { Observable } from 'rxjs';
import cache, { cacheClean } from 'rxjs-operators/cache';

import apiService from './api';

export class PropertyService {
  list(params: IPaginationParams): Observable<IPaginationResponse<IProperty>> {
    return apiService.get('/property', params);
  }

  types(): Observable<IPropertyType[]> {
    return apiService.get('/property/types').pipe(cache('property-types'));
  }

  ownerTypes(): Observable<IPropertyOwnerType[]> {
    return apiService.get('/property/owner-types').pipe(cache('property-owner-types'));
  }

  olxCategories(): Observable<IPropertyOlxCategory[]> {
    return apiService.get('/property/olx-categories').pipe(cache('property-olx-categories'));
  }

  imovelWebCategories(): Observable<IPropertyOlxCategory[]> {
    return apiService.get('/property/imovelweb-categories').pipe(cache('property-imovelweb-categories'));
  }

  benefits(): Observable<IPropertyExtraBenefit[]> {
    return apiService.get('/property/benefits').pipe(cache('property-benefits'));
  }

  get(id: number): Observable<IProperty> {
    return apiService.get<IProperty>(`/property/${id}`);
  }

  save(model: Partial<IPropertyForm>): Observable<IProperty> {
    return apiService.post<IProperty>('/property', model).pipe(cacheClean('dashboard-totals'));
  }

  featured(
    model: Partial<{
      id: number;
      featured: boolean;
      featuredImovelWeb: enPropertyFeaturedImovelWeb;
    }>
  ): Observable<void> {
    return apiService.post('/property/featured', model).pipe(cacheClean('dashboard-featured'));
  }

  remove(id: number): Observable<IProperty> {
    return apiService
      .delete<IProperty>(`/property/${id}`)
      .pipe(cacheClean('dashboard-totals'), cacheClean('dashboard-featured'));
  }

  statusUpdate(id: number, status: enPropertyStatus): Observable<IProperty> {
    return apiService
      .post<IProperty>(`/property/${id}/status/${status}`)
      .pipe(cacheClean('dashboard-totals'), cacheClean('dashboard-featured'));
  }
}

const propertyService = new PropertyService();
export default propertyService;
