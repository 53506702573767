import { PureComponent, SyntheticEvent } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Alert from 'components/Shared/Alert';
import DropdownMenu from 'components/Shared/DropdownMenu';
import OptionItem from 'components/Shared/DropdownMenu/OptionItem';
import ErrorMessage from 'components/Shared/ErrorMessage';
import IconMessage from 'components/Shared/IconMessage';
import Toast from 'components/Shared/Toast';
import { IStyledProps, WithStyles } from 'decorators/withStyles';
import IAddress from 'interfaces/models/address';
import IAddressZone from 'interfaces/models/addressZone';
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import EditIcon from 'mdi-react/EditIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import { Subject, debounceTime, filter, first, tap } from 'rxjs';
import bindComponent from 'rxjs-operators/bindComponent';
import { loader } from 'rxjs-operators/loader';
import addressZoneService from 'services/addressZone';

import Address from './Address';

interface IState {
  expanded: boolean;
  addresses: IAddress[];
  loading: boolean;
  error?: any;
}

interface IProps extends IStyledProps {
  data: IAddressZone;
}

@WithStyles({
  loader: {
    textAlign: 'center'
  },
  actions: {
    textAlign: 'right',
    marginBottom: -15,
    marginRight: -15,
    marginTop: 10
  }
})
export default class ListItem extends PureComponent<IProps, IState> {
  expanded$: Subject<boolean>;

  constructor(props: IProps) {
    super(props);
    this.expanded$ = new Subject();
    this.state = { expanded: false, loading: false, addresses: [] };
  }

  componentDidMount() {
    this.expanded$.pipe(bindComponent(this)).subscribe(expanded => this.setState({ expanded }));

    this.expanded$
      .pipe(
        filter(expanded => expanded),
        first(),
        bindComponent(this)
      )
      .subscribe(() => this.loadData());
  }

  loadData = () => {
    this.setState({ loading: true, error: null });

    addressZoneService
      .getAddresses(this.props.data.id)
      .pipe(bindComponent(this), debounceTime(500))
      .subscribe({
        next: result => this.setState({ addresses: result || [], loading: false }),
        error: error => this.setState({ loading: false, error })
      });
  };

  handleChange = (event: SyntheticEvent, expanded: boolean) => {
    this.expanded$.next(expanded);
  };

  handleCreateAddress = () => {
    addressZoneService
      .openFormAddress(this.props.data.id)
      .pipe(bindComponent(this))
      .subscribe({
        next: () => null,
        error: err => Toast.error(err)
      });
  };

  onEdit = () => addressZoneService.openForm(this.props.data);
  onDelete = async () => {
    const isOK = await Alert.confirm({
      message: (
        <span>
          Deseja apagar o grupo <strong>{this.props.data.name}</strong>?
          <br />
          <br />
          Isso não afetará os imóveis já cadastrados.
        </span>
      )
    });

    if (!isOK) return;

    addressZoneService
      .delete(this.props.data.id)
      .pipe(
        loader(),
        tap(() => Toast.show('Grupo excluido'))
      )
      .subscribe({
        next: () => null,
        error: err => Toast.error(err)
      });
  };

  render() {
    const { expanded, loading, error, addresses } = this.state;
    const { data, classes } = this.props;

    return (
      <ExpansionPanel expanded={expanded} onChange={this.handleChange}>
        <ExpansionPanelSummary expandIcon={<ChevronDownIcon />}>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={true}>
              <Typography variant='subtitle1'>
                {data.name}
                {!!data.isCondon && <Typography variant='caption'> - Condomínio</Typography>}
                {!!data.benefits?.length && (
                  <Typography variant='caption'> - {data.benefits?.length} benefícios</Typography>
                )}
              </Typography>
            </Grid>
            <DropdownMenu>
              <OptionItem text='Editar' icon={EditIcon} handler={this.onEdit} />
              <OptionItem text='Excluir' icon={DeleteIcon} handler={this.onDelete} />
            </DropdownMenu>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {loading && (
            <div className={classes.loader}>
              <CircularProgress color='secondary' />
            </div>
          )}

          {!loading && !!error && (
            <div className={classes.loader}>
              <ErrorMessage error={error} tryAgain={this.loadData} />
            </div>
          )}

          {!loading && !error && !addresses.length && (
            <IconMessage icon={AlertCircleOutlineIcon} message='Nenhum endereço cadastrado' />
          )}

          {!loading && !error && addresses.map(address => <Address key={address.id} data={address} />)}

          {!loading && !error && (
            <div className={classes.actions}>
              <Button color='secondary' onClick={this.handleCreateAddress}>
                <PlusIcon />
                Novo Endereço
              </Button>
            </div>
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}
