import { map, Observable } from 'rxjs';

import apiService from './api';

export class UploadService {
  public saveImage(base64: string, filename = 'upload-image.png'): Observable<{ url: string; progress: number }> {
    return apiService.upload<string>('/content/upload', { filename, base64 }).pipe(
      map(({ response, progress }) => {
        return {
          url: response ? response : null,
          progress
        };
      })
    );
  }
}

const uploadService = new UploadService();
export default uploadService;
