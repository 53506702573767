import { PureComponent } from 'react';

import { Route, Switch } from 'react-router-dom';

import { AddressZoneListPage } from './List';

export default class AddressZonePage extends PureComponent {
  render() {
    return (
      <Switch>
        <Route path='/' component={AddressZoneListPage} />
      </Switch>
    );
  }
}
