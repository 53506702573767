import { Component } from 'react';

import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FieldText from '@react-form-fields/material-ui/components/Text';
import { WithStyles } from 'decorators/withStyles';
import textCounter from 'helpers/textCounter';

import PropertyFormPage from '..';

interface IState {}

interface IProps {
  disabled?: boolean;
  model: PropertyFormPage['state']['model'];
  updateModel: PropertyFormPage['updateModel'];
  classes?: any;
}

@WithStyles(theme => ({
  marginTitle: {
    [theme.breakpoints.up('sm')]: {
      marginTop: 35
    }
  }
}))
export default class PropertyFormBasic extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  shouldComponentUpdate(nextProps: Readonly<IProps>) {
    if (
      nextProps.disabled !== this.props.disabled ||
      nextProps.model?.title !== this.props.model?.title ||
      nextProps.model?.description !== this.props.model?.description
    ) {
      return true;
    }

    return false;
  }

  render() {
    const { model, updateModel, disabled, classes } = this.props;

    return (
      <CardContent>
        <Typography variant='h6' gutterBottom>
          Informações Básicas
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FieldText
              label='Título'
              value={model.title}
              disabled={disabled}
              helperText={textCounter(model.title, 100)}
              validation='string|required|min:10|max:100'
              onChange={updateModel((m, v) => (m.title = v))}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography className={classes.marginTitle}>
              Prévia OLX:&nbsp;
              <strong>{`#${model.title || ''}`.substr(0, 20)}</strong>
            </Typography>
            <Typography variant='caption'>
              Limite de {textCounter(`1 ${model.title || ''}`.substr(0, 20), 20)}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <FieldText
              label='Descrição'
              value={model.description}
              disabled={disabled}
              multiline
              validation='string|required|min:1|max:3000'
              helperText={textCounter(model.description, 3000)}
              onChange={updateModel((m, v) => (m.description = v))}
            />
          </Grid>
        </Grid>
      </CardContent>
    );
  }
}
