import { catchError, map, Observable, of } from 'rxjs';

import { apiResponseFormatter } from '../formatters/apiResponse';

export class StorageService {
  public get<T = any>(key: string): Observable<T> {
    return of(true).pipe(
      map(() => localStorage.getItem(key)),
      map(data => (data ? apiResponseFormatter(JSON.parse(data)) : null)),
      catchError(() => of(null))
    );
  }

  public set<T = any>(key: string, value: T): Observable<T> {
    return of(true).pipe(
      map(() => localStorage.setItem(key, JSON.stringify(value))),
      map(() => value)
    );
  }

  public clear(regexp: RegExp): Observable<void> {
    return of(Object.keys(localStorage)).pipe(
      map(keys => {
        if (regexp) {
          keys = keys.filter(k => regexp.test(k));
        }

        keys.forEach(k => localStorage.removeItem(k));
      })
    );
  }
}

const storageService = new StorageService();
export default storageService;
