import { Component, Fragment } from 'react';

import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CustomMessage from '@react-form-fields/core/components/CustomMessage';
import FieldSelect from '@react-form-fields/material-ui/components/Select';
import FieldText from '@react-form-fields/material-ui/components/Text';
import { enTransactionType } from 'interfaces/models/property';
import ISelectItem from 'interfaces/selectItem';
import isEqual from 'lodash/isEqual';

import PropertyFormPage from '..';

interface IState {
  rentalPeriod: ISelectItem[];
  transactionTypes: ISelectItem[];
}

interface IProps {
  disabled?: boolean;
  model: PropertyFormPage['state']['model'];
  updateModel: PropertyFormPage['updateModel'];
}

export default class PropertyFormNegociation extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      rentalPeriod: [
        { value: 'Monthly', label: 'Mensal' },
        { value: 'Daily', label: 'Diário' },
        { value: 'Weekly', label: 'Semanal' },
        { value: 'Quarterly', label: 'Trimestral' },
        { value: 'Yearly', label: 'Anual' }
      ],
      transactionTypes: [
        { value: enTransactionType.sale, label: 'Venda' },
        { value: enTransactionType.rent, label: 'Aluguel' },
        { value: enTransactionType.both, label: 'Venda e Aluguel' }
      ]
    };
  }

  shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>) {
    if (!isEqual(nextState, this.state)) {
      return true;
    }

    if (
      nextProps.disabled !== this.props.disabled ||
      nextProps.model?.transactionType !== this.props.model?.transactionType ||
      nextProps.model?.salesPrice !== this.props.model?.salesPrice ||
      nextProps.model?.rentalPrice !== this.props.model?.rentalPrice ||
      nextProps.model?.rentalPeriod !== this.props.model?.rentalPeriod ||
      nextProps.model?.propertyAdministrationFee !== this.props.model?.propertyAdministrationFee ||
      nextProps.model?.yearlyTax !== this.props.model?.yearlyTax
    ) {
      return true;
    }

    return false;
  }

  render() {
    const { transactionTypes, rentalPeriod } = this.state;
    const { model, updateModel, disabled } = this.props;

    return (
      <CardContent>
        <Typography variant='h6' gutterBottom>
          Negociação e Valores
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <FieldSelect
              label='Tipo de Negociação'
              value={model.transactionType}
              disabled={disabled}
              emptyOption='Selecione...'
              options={transactionTypes}
              validation='required'
              onChange={updateModel((m, v) => (m.transactionType = v))}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          {!!model.transactionType && model.transactionType !== enTransactionType.rent && (
            <Grid item xs={12} sm={4}>
              <FieldText
                label='Valor de venda'
                value={model.salesPrice}
                disabled={disabled}
                mask='money'
                validation='required|integer|min:0'
                onChange={updateModel((m, v) => (m.salesPrice = v))}
              >
                <CustomMessage rules='integer'>Não pode conter centavos</CustomMessage>
              </FieldText>
            </Grid>
          )}

          {!!model.transactionType && model.transactionType !== enTransactionType.sale && (
            <Fragment>
              <Grid item xs={12} sm={4}>
                <FieldText
                  label='Valor de aluguel'
                  value={model.rentalPrice}
                  disabled={disabled}
                  mask='money'
                  validation='required|integer|min:0'
                  onChange={updateModel((m, v) => (m.rentalPrice = v))}
                >
                  <CustomMessage rules='integer'>Não pode conter centavos</CustomMessage>
                </FieldText>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FieldSelect
                  label='Periodicidade'
                  value={model.rentalPeriod}
                  disabled={disabled}
                  emptyOption='Selecione...'
                  options={rentalPeriod}
                  validation='required'
                  onChange={updateModel((m, v) => (m.rentalPeriod = v))}
                />
              </Grid>
            </Fragment>
          )}
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FieldText
              label='Condomínio / Mês'
              value={model.propertyAdministrationFee}
              disabled={disabled}
              mask='money'
              validation='integer|min:0'
              onChange={updateModel((m, v) => (m.propertyAdministrationFee = v))}
            >
              <CustomMessage rules='integer'>Não pode conter centavos</CustomMessage>
            </FieldText>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FieldText
              label='IPTU / Ano'
              value={model.yearlyTax}
              disabled={disabled}
              mask='money'
              validation='integer|min:0'
              onChange={updateModel((m, v) => (m.yearlyTax = v))}
            >
              <CustomMessage rules='integer'>Não pode conter centavos</CustomMessage>
            </FieldText>
          </Grid>
        </Grid>
      </CardContent>
    );
  }
}
