import { PureComponent } from 'react';

import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Alert from 'components/Shared/Alert';
import { IImageReaderResult } from 'components/Shared/ImageSelector/ImageReader';
import { IStyledProps, WithStyles } from 'decorators/withStyles';
import { errorMessageFormatter } from 'formatters/errorMessage';
import imageUrl from 'helpers/imageUrl';
import AlertCircleIcon from 'mdi-react/AlertCircleIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import { SortableElement } from 'react-sortable-hoc';
import bindComponent from 'rxjs-operators/bindComponent';
import uploadService from 'services/upload';

interface IState {
  loading: boolean;
  progress: number;
  error?: any;
}

interface IProps extends IStyledProps {
  disabled: boolean;
  insideDialog: boolean;
  data: string | IImageReaderResult;
  onUploadSuccess: (data: IImageReaderResult, url: string) => void;
  onDelete: (data: string | IImageReaderResult) => void;
}

@WithStyles(theme => ({
  container: {
    textAlign: 'center',
    height: '130px',
    lineHeight: '130px',
    position: 'relative',
    cursor: 'move',
    backgroundColor: 'rgba(0,0,0,0.8)',
    borderRadius: 0
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
    verticalAlign: 'middle',
    pointerEvents: 'none'
  },
  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: '3px',
    background: 'rgba(255, 255, 255, 0.60)',
    lineHeight: '130px',
    '& > div': {
      verticalAlign: 'middle'
    }
  },
  dangerIcon: {
    color: theme.palette.error.main,
    height: 30,
    width: 30
  },
  deleteButton: {
    position: 'absolute',
    right: 2,
    top: 2,
    color: 'white',
    background: 'rgba(0, 0, 0, 0.34)',
    padding: 8
  }
}))
class Image extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      progress: 0,
      loading: typeof props.data !== 'string'
    };
  }

  get imageSrc() {
    const { data } = this.props;
    return typeof data === 'string' ? imageUrl(data) : data.base64;
  }

  componentDidMount() {
    this.handleUpload();
  }

  handleUpload = () => {
    const { data } = this.props;
    if (typeof data === 'string') return;

    this.setState({ loading: true, progress: 0, error: null });

    uploadService
      .saveImage(data.base64, data.filename)
      .pipe(bindComponent(this))
      .subscribe({
        next: ({ url, progress }) => {
          this.setState({ progress });

          if (!url) return;
          this.props.onUploadSuccess(data, url);
        },
        error: error => this.setState({ error, loading: false })
      });
  };

  handleTryAgain = async () => {
    const tryAgain = await Alert.confirm({
      title: 'Erro',
      message: (
        <span>
          {errorMessageFormatter(this.state.error)}
          <br />
          <br />
          Deseja tentar novamente?
        </span>
      )
    });

    if (tryAgain) {
      this.handleUpload();
      return;
    }

    this.props.onDelete(this.props.data);
  };

  handleDelete = () => {
    this.props.onDelete(this.props.data);
  };

  render() {
    const { classes, insideDialog, disabled } = this.props;
    const { progress, loading, error } = this.state;

    return (
      <Grid item xs={6} sm={insideDialog ? 4 : 2} md={insideDialog ? 4 : 2}>
        <Card className={classes.container}>
          {(loading || !!error) && (
            <div className={classes.loader}>
              {loading && (
                <CircularProgress
                  variant={progress !== 100 ? 'determinate' : 'indeterminate'}
                  color='secondary'
                  value={progress}
                />
              )}
              {!!error && (
                <IconButton onClick={this.handleTryAgain}>
                  <AlertCircleIcon className={classes.dangerIcon} />
                </IconButton>
              )}
            </div>
          )}

          {!loading && !error && !disabled && (
            <IconButton onClick={this.handleDelete} className={classes.deleteButton}>
              <DeleteIcon />
            </IconButton>
          )}

          <img src={this.imageSrc} className={classes.img} />
        </Card>
      </Grid>
    );
  }
}

export default SortableElement(Image);
