import App from 'App';
import * as ReactDOM from 'react-dom';
import logService from 'services/log';

import setHoustonHooksConfig from '@eduzz/houston-hooks/config';

setHoustonHooksConfig({
  onUnhandledError: err => logService.handleError(err)
});

ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then(registration => {
    registration.unregister();
  });
}
