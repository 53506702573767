import IProperty, { enPropertyStatus, enRentalPeriod, enTransactionType } from 'interfaces/models/property';
import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import PlayCircleIcon from 'mdi-react/PlayCircleIcon';

import moneyFormat from './money';

export function formatTransactionType(value: enTransactionType) {
  switch (value) {
    case enTransactionType.rent:
      return 'Aluguel';
    case enTransactionType.sale:
      return 'Venda';
    default:
      return 'Venda e Aluguel';
  }
}

export function formatPrice(property: IProperty) {
  switch (property.transactionType) {
    case enTransactionType.rent:
      return moneyFormat(property.rentalPrice);
    case enTransactionType.sale:
      return moneyFormat(property.salesPrice);
    default:
      return moneyFormat(property.salesPrice) + ' - ' + moneyFormat(property.rentalPrice);
  }
}

export function formatStatus(status: enPropertyStatus, plural = false): string {
  switch (status) {
    case enPropertyStatus.completed:
      return 'Finalizado' + (plural ? 's' : '');
    case enPropertyStatus.canceled:
      return 'Cancelado' + (plural ? 's' : '');
    default:
      return 'Ativo' + (plural ? 's' : '');
  }
}

export function formatStatusIcon(status: enPropertyStatus): typeof CheckCircleIcon {
  switch (status) {
    case enPropertyStatus.completed:
      return CheckCircleIcon;
    case enPropertyStatus.canceled:
      return CloseCircleIcon;
    default:
      return PlayCircleIcon;
  }
}

export function formatRentalPeriod(status: enRentalPeriod): string {
  switch (status) {
    case enRentalPeriod.monthly:
      return 'Mensal';
    case enRentalPeriod.daily:
      return 'Diário';
    case enRentalPeriod.weekly:
      return 'Semanal';
    case enRentalPeriod.quarterly:
      return 'Trimestral';
    case enRentalPeriod.yearly:
      return 'Anual';
  }
}
