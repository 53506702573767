import { ThemeVariables } from '@material-ui/core/styles/createTheme';

const variables: ThemeVariables = {
  drawerWidth: 240,
  headerHeight: 56,
  headerHeightUpSm: 64,
  tabbarHeight: 48,
  contentPadding: 12,
  contentPaddingUpSm: 24,
  boxShadow:
    '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
  colors: {
    facebook: '#3b5998',
    google: '#de5245',
    featured: '#f7c42c',
    statusCompleted: '#a3cd3b',
    statusCanceled: '#d82114',
    featuredImovelWeb: '#2ca2f7',
    featuredImovelWebSuper: '#7e57c2'
  }
};

export default variables;
