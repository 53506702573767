import { PureComponent } from 'react';

import { Route, Switch } from 'react-router-dom';

import PropertyFormPage from './Form';
import PropertyListPage from './List';

export default class PropertyPage extends PureComponent {
  render() {
    return (
      <Switch>
        <Route path='*/novo' component={PropertyFormPage} />
        <Route path='*/:id/editar/:olx?' component={PropertyFormPage} />
        <Route path='*/' component={PropertyListPage} />
      </Switch>
    );
  }
}
