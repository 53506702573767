import { PureComponent, Fragment } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FieldSelect from '@react-form-fields/material-ui/components/Select';
import Toast from 'components/Shared/Toast';
import IPropertyOlxCategory from 'interfaces/models/propertyOlxCategory';
import ISelectItem from 'interfaces/selectItem';
import bindComponent from 'rxjs-operators/bindComponent';
import propertyService from 'services/property';

import PropertyFormPage from '../..';

interface IState {
  loading: boolean;
  allCategories: IPropertyOlxCategory[];

  level1Value?: number;
  level2Value?: number;

  level1Options?: ISelectItem<number>[];
  level2Options?: ISelectItem<number>[];
  level3Options?: ISelectItem<number>[];
}

interface IProps {
  disabled?: boolean;
  model: PropertyFormPage['state']['model'];
  updateModel: PropertyFormPage['updateModel'];
}

export default class PropertyFormOlxCategory extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      ...this.state,
      level1Options: [],
      level2Options: [],
      level3Options: [],
      loading: true
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { model } = this.props;

    this.setState({
      level1Value: model.propertyOlxCategory ? model.propertyOlxCategory.parent.parentId : null,
      level2Value: model.propertyOlxCategory ? model.propertyOlxCategory.parentId : null
    });

    propertyService
      .olxCategories()
      .pipe(bindComponent(this))
      .subscribe({
        next: result => this.resolveOptions({ allCategories: result ?? [], loading: false }),
        error: err => Toast.error(err)
      });
  }

  resolveOptions = (newState: Partial<IState> = {}) => {
    const { allCategories, level1Value, level2Value } = {
      ...this.state,
      ...newState
    };

    this.setState({
      ...(newState as IState),
      level1Options: allCategories.filter(c => !c.parentId).map(this.mapper),
      level2Options: level1Value ? allCategories.filter(c => c.parentId === level1Value).map(this.mapper) : [],
      level3Options: level2Value ? allCategories.filter(c => c.parentId === level2Value).map(this.mapper) : []
    });
  };

  mapper = (c: IPropertyOlxCategory) => ({ value: c.id, label: c.display });

  handleChangeLevel1 = (value: number) => {
    this.resolveOptions({ level1Value: value, level2Value: null });
    this.props.updateModel((m, v) => (m.propertyOlxCategoryId = v))(null);
  };

  handleChangeLevel2 = (value: number) => {
    this.resolveOptions({ level2Value: value });
    this.props.updateModel((m, v) => (m.propertyOlxCategoryId = v))(null);
  };

  render() {
    const { loading, level1Options, level1Value, level2Options, level2Value, level3Options } = this.state;
    const { model, updateModel, disabled } = this.props;

    return (
      <Fragment>
        <Typography component='p' variant='subtitle1'>
          Categorias OLX
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={4}>
            <FieldSelect
              label='Tipo do Imovel'
              value={level1Value}
              disabled={disabled}
              loading={loading}
              options={level1Options}
              emptyOption='Selecine...'
              onChange={this.handleChangeLevel1}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={4}>
            <FieldSelect
              label='SubTipo do Imovel'
              value={level2Value}
              disabled={disabled}
              loading={loading}
              options={level2Options}
              emptyOption='Selecine...'
              onChange={this.handleChangeLevel2}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={4}>
            <FieldSelect
              label='Categoria do Imovel'
              value={model.propertyOlxCategoryId}
              disabled={disabled}
              loading={loading}
              options={level3Options}
              emptyOption='Selecine...'
              onChange={updateModel((m, v) => (m.propertyOlxCategoryId = v))}
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}
