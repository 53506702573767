import { Fragment } from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ListItemComponent, { IStateListItem } from 'components/Abstract/ListItem';
import DropdownMenu from 'components/Shared/DropdownMenu';
import OptionItem from 'components/Shared/DropdownMenu/OptionItem';
import { IStyledProps, WithStyles } from 'decorators/withStyles';
import { formatPrice } from 'formatters/property';
import imageUrl from 'helpers/imageUrl';
import IProperty from 'interfaces/models/property';
import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import EditIcon from 'mdi-react/EditIcon';
import CircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';
import bindComponent from 'rxjs-operators/bindComponent';
import olxService from 'services/olx';

interface IState extends IStateListItem {}

interface IProps extends IStyledProps {
  property: IProperty;
  forAddGroupId?: number;
  forAddChecked?: boolean;
  onEdit?: (property: IProperty) => void;
  onDelete?: (property: IProperty) => void;
  onAddChange?: (property: IProperty, added: boolean) => void;
}

@WithStyles(theme => ({
  img: {
    minWidth: '100%',
    width: 120,
    height: 80,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    margin: 'auto',
    position: 'relative'
  },
  grid: {
    minHeight: '100%',
    padding: 4,
    margin: 0
  },
  price: {
    bottom: 0,
    left: 0,
    right: 0,
    padding: 5,
    position: 'absolute',
    fontSize: 16,
    color: 'white',
    background: 'linear-gradient(180deg, transparent, rgba(0, 0, 0, .87))'
  },
  featuredIcon: {
    color: theme.variables.colors.featured
  },
  statusIcon: {
    verticalAlign: 'text-bottom'
  },
  statusIconcompleted: {
    color: theme.variables.colors.statusCompleted
  },
  statusIconcanceled: {
    color: theme.variables.colors.statusCanceled
  },
  actionCenter: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(1)
  }
}))
export default class PropertyListItem extends ListItemComponent<IProps, IState> {
  onEdit = () => this.props.onEdit && this.props.onEdit(this.props.property);
  onDelete = () => this.props.onDelete && this.props.onDelete(this.props.property);
  onAddChange = () => {
    const stream$ = !this.props.forAddChecked
      ? olxService.addToGroup(this.props.forAddGroupId, this.props.property.id)
      : olxService.removeFromGroup(this.props.forAddGroupId, this.props.property.id);

    this.setState({ loading: true });

    stream$.pipe(bindComponent(this)).subscribe({
      next: () => {
        this.setState({ loading: false });
        this.props.onAddChange && this.props.onAddChange(this.props.property, !this.props.forAddChecked);
      },
      error: error => this.setState({ error, loading: false })
    });
  };

  render() {
    const { property, classes, forAddGroupId, forAddChecked } = this.props;

    return (
      <Fragment>
        <Divider />

        <Grid container>
          <Grid item xs={12} sm='auto'>
            <div
              className={classes.img}
              style={{
                backgroundImage: `url(${imageUrl(property.images[0])})`
              }}
            >
              <div className={classes.price}>{formatPrice(property)}</div>
            </div>
          </Grid>
          <Grid item xs={true}>
            <Grid container direction='column' className={classes.grid} spacing={2}>
              <Grid item xs={true}>
                <Typography variant='subtitle1'>{property.title}</Typography>
                {!!property.propertyOlxCategory && (
                  <Typography variant='body2'>
                    {property.propertyOlxCategory.parent.parent.display} •&nbsp;
                    {property.propertyOlxCategory.parent.display} •&nbsp;
                    {property.propertyOlxCategory.display}
                  </Typography>
                )}
                {!property.propertyOlxCategory && (
                  <Typography variant='body2' color='error'>
                    Sem categoria OLX definida, esse imóvel não será enviado
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={false} className={forAddGroupId ? classes.actionCenter : ''}>
            {!forAddGroupId && (
              <DropdownMenu>
                <OptionItem text='Editar' icon={EditIcon} handler={this.onEdit} />
                <OptionItem text='Remover do Grupo' icon={DeleteIcon} handler={this.onDelete} />
              </DropdownMenu>
            )}

            {!!forAddGroupId &&
              this.renderSideMenu({
                icon: forAddChecked ? CheckCircleIcon : CircleOutlineIcon,
                handler: this.onAddChange
              })}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}
