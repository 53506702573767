import { IDashboardTotals, IDashboardFeatured } from 'interfaces/models/dashboard';
import { IDashboardSync } from 'interfaces/models/sync';
import { Observable } from 'rxjs';
import cache from 'rxjs-operators/cache';

import apiService, { ApiService } from './api';

export class DashboardService {
  constructor(private apiService: ApiService) {}

  public totals(): Observable<IDashboardTotals> {
    return this.apiService.get('/dashboard/totals').pipe(cache('dashboard-totals'));
  }

  public featured(): Observable<IDashboardFeatured> {
    return this.apiService.get('/dashboard/featured').pipe(cache('dashboard-featured'));
  }

  public sync(): Observable<IDashboardSync> {
    return this.apiService.get('/dashboard/sync');
  }
}

const dashboardService = new DashboardService(apiService);
export default dashboardService;
