import createTheme from '@material-ui/core/styles/createTheme';

import overrides from './overrides';
import props from './props';
import variables from './variables';

const primary = {
  light: '#4f5b62',
  main: '#263238',
  dark: '#000a12',
  contrastText: '#fff'
};

const secondary = {
  light: '#64d8cb',
  main: '#26a69a',
  dark: '#00766c',
  contrastText: '#fff'
};

export const theme = createTheme({
  palette: { primary, secondary },
  overrides,
  variables,
  props
});

export const reverseTheme = createTheme({
  palette: { primary: secondary, secondary: primary },
  overrides,
  variables,
  props
});
