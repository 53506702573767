import { Fragment, PureComponent } from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Alert from 'components/Shared/Alert';
import DropdownMenu from 'components/Shared/DropdownMenu';
import OptionItem from 'components/Shared/DropdownMenu/OptionItem';
import Toast from 'components/Shared/Toast';
import { IRouteProps, WithRouter } from 'decorators/withRouter';
import IOlxGroup from 'interfaces/models/olxGroup';
import IProperty from 'interfaces/models/property';
import CheckIcon from 'mdi-react/CheckIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import EditIcon from 'mdi-react/EditIcon';
import bindComponent from 'rxjs-operators/bindComponent';
import { loader } from 'rxjs-operators/loader';
import olxService from 'services/olx';

import PropertyListItem from '../PropertyItem';

interface IProps extends IRouteProps {
  group: IOlxGroup;
  onAddProperty: (group: IOlxGroup) => void;
}

@WithRouter()
export default class GroupItem extends PureComponent<IProps> {
  handleAdd = () => {
    this.props.onAddProperty(this.props.group);
  };

  handleDelete = async () => {
    const isOk = await Alert.confirm(`Deseja realmente remover o ${this.props.group.name}?`);
    if (!isOk) return;

    olxService
      .remove(this.props.group.id)
      .pipe(loader(), bindComponent(this))
      .subscribe({
        next: () => Toast.show('Grupo removido'),
        error: err => Toast.error(err)
      });
  };

  handleEditProperty = (property: IProperty) => this.props.history.push(`/imoveis/${property.id}/editar?olx`);

  handleDeleteProperty = async (property: IProperty) => {
    const isOk = await Alert.confirm(`Deseja realmente remover ${property.title} do ${this.props.group.name}?`);
    if (!isOk) return;

    olxService
      .removeFromGroup(this.props.group.id, property.id)
      .pipe(loader(), bindComponent(this))
      .subscribe({
        next: () => Toast.show('Imóvel removido'),
        error: err => Toast.error(err)
      });
  };

  render() {
    const { group } = this.props;

    return (
      <Card>
        <CardContent>
          <Grid container spacing={1} alignItems='center'>
            <Grid item xs={true}>
              <Typography variant='subtitle1'>{group.name}</Typography>
            </Grid>
            <Grid item xs='auto'>
              {group.active && <Chip icon={<CheckIcon />} label='Ativo - Atual' color='secondary' />}
              {group.previousActive && <Chip icon={<CheckIcon />} label='Ativo - Anterior' color='secondary' />}
            </Grid>
            <Grid item xs='auto'>
              <DropdownMenu>
                <OptionItem text='Adicionar Anúncio' icon={EditIcon} handler={this.handleAdd} />
                <OptionItem text='Remover' icon={DeleteIcon} handler={this.handleDelete} />
              </DropdownMenu>
            </Grid>
          </Grid>
        </CardContent>

        {!group.properties.length && (
          <Fragment>
            <Divider />

            <CardContent>
              <Typography variant='subtitle1'>Nenhum imóvel adicionado a esse grupo</Typography>
            </CardContent>
          </Fragment>
        )}

        {group.properties.map(property => (
          <PropertyListItem
            key={property.id}
            property={property}
            onEdit={this.handleEditProperty}
            onDelete={this.handleDeleteProperty}
          />
        ))}
      </Card>
    );
  }
}
