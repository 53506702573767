import { Fragment } from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import FieldSelect from '@react-form-fields/material-ui/components/Select';
import FieldText from '@react-form-fields/material-ui/components/Text';
import { IStateList, ListComponent } from 'components/Abstract/List';
import Toolbar from 'components/Layout/Toolbar';
import Alert from 'components/Shared/Alert';
import ErrorMessage from 'components/Shared/ErrorMessage';
import FabButton from 'components/Shared/FabButton';
import Toast from 'components/Shared/Toast';
import { IRouteProps } from 'decorators/withRouter';
import { IStyledProps, WithStyles } from 'decorators/withStyles';
import { formatStatus } from 'formatters/property';
import IProperty, { enPropertyStatus } from 'interfaces/models/property';
import { IPaginationParams } from 'interfaces/pagination';
import ISelectItem from 'interfaces/selectItem';
import ArrowDownIcon from 'mdi-react/ArrowDownIcon';
import ArrowUpIcon from 'mdi-react/ArrowUpIcon';
import BroomIcon from 'mdi-react/BroomIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import bindComponent from 'rxjs-operators/bindComponent';
import { loader } from 'rxjs-operators/loader';
import propertyService from 'services/property';

import PropertyListItem from './ListItem';
import PropertyDetailsDialog from '../Details';
import PropertyFeaturedDialog from '../Featured';

interface IState extends IStateList<IProperty> {
  categoryId: number;
  imovelWeb?: 'enabled' | 'disabled';
  status: enPropertyStatus;
  ownerTypeId?: number;
  ownerAcceptGreatherPrice?: boolean;
  ownerAcceptExchange?: boolean;
  minSalesValue?: number;
  maxSalesValue?: number;

  property?: IProperty;
  showFeatured: boolean;
  showDetails: boolean;

  loadingOwnerTypes: boolean;
  ownerTypes: ISelectItem<number>[];

  loadingCategories: boolean;
  categories: ISelectItem<number>[];
}

interface IProps extends IRouteProps, IStyledProps {}

@WithStyles({
  orderIcon: {
    margin: '-8px -8px 0px -8px'
  },
  emptyMessage: {
    textAlign: 'center',
    fontStyle: 'italic',
    fontSize: 16,
    opacity: 0.6
  },
  cancelButton: {
    textAlign: 'right'
  },
  salesPrice: {
    display: 'flex',
    position: 'relative',
    '&:after': {
      content: '" "',
      position: 'absolute',
      top: 'calc(50% + 5px)',
      left: '50%',
      marginLeft: -5,
      borderBottom: '1px solid #ced4da',
      width: 10,
      height: 5
    },
    '& > div': {
      flex: 1,
      '&:first-child > label + div': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRight: 0
      },
      '&:last-child > label + div': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderLeft: 0
      }
    }
  }
})
export default class PropertyListPage extends ListComponent<IProps, IState> {
  timeout: any = null;
  orderByColumns: ISelectItem[] = [
    { label: 'Título', value: 'title' },
    { label: 'Data de Criação', value: 'createdDate' },
    { label: 'Data de Atualização', value: 'updatedDate' },
    { label: 'Valor de Venda', value: 'salesPrice' }
  ];
  statuses: ISelectItem[] = [
    { label: formatStatus(enPropertyStatus.pending), value: enPropertyStatus.pending },
    { label: formatStatus(enPropertyStatus.completed), value: enPropertyStatus.completed },
    { label: formatStatus(enPropertyStatus.canceled), value: enPropertyStatus.canceled }
  ];
  acceptExchangeOptiosn: ISelectItem[] = [
    { label: 'Não', value: 'no' },
    { label: 'Sim', value: 'yes' }
  ];
  actions = [{ icon: PlusIcon, onClick: () => this.handleCreate() }];

  constructor(props: {}) {
    super(props, 'createdDate');

    const sessionParams = JSON.parse(sessionStorage.getItem('property-list-params') || '{}');
    this.state = {
      ...this.state,
      status: enPropertyStatus.pending,
      showFeatured: false,
      showDetails: false,
      loadingCategories: true,
      loadingOwnerTypes: true,
      ...sessionParams
    };
  }

  componentDidMount() {
    this.loadData();

    propertyService
      .ownerTypes()
      .pipe(bindComponent(this))
      .subscribe({
        next: result => {
          this.setState({
            ownerTypes: result?.map(t => ({ value: t.id, label: t.title })) ?? [],
            loadingOwnerTypes: false
          });
        },
        error: err => Toast.error(err)
      });

    propertyService
      .types()
      .pipe(bindComponent(this))
      .subscribe({
        next: result => {
          this.setState({
            categories: result?.map(t => ({ value: t.id, label: t.display })) ?? [],
            loadingCategories: false
          });
        },
        error: err => Toast.error(err)
      });
  }

  loadData = (params: Partial<IPaginationParams> = {}) => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(
      () => {
        this.setState({ loading: true, error: null });
        const newParams = this.mergeParams(params);
        sessionStorage.setItem('property-list-params', JSON.stringify(newParams));

        propertyService
          .list(newParams)
          .pipe(bindComponent(this))
          .subscribe({
            next: items => this.setPaginatedData(items),
            error: error => this.setError(error)
          });
      },
      this.timeout ? 1000 : 0
    );
  };

  mergeParams(params: Partial<IPaginationParams>): IPaginationParams {
    const { categoryId, imovelWeb, status, ownerTypeId, ownerAcceptExchange, minSalesValue, maxSalesValue } =
      this.state;
    return {
      ...super.mergeParams(params),
      categoryId,
      imovelWeb,
      status,
      ownerTypeId,
      ownerAcceptExchange,
      minSalesValue,
      maxSalesValue
    };
  }

  handleChangeOrderBy = (orderBy: string) => {
    if (this.state.loading) return;
    this.setState({ orderBy, page: 0 }, () => this.loadData());
  };

  handleToggleOrderDirection = () => {
    const { loading, orderDirection } = this.state;

    if (loading) return;
    this.setState({ orderDirection: orderDirection === 'asc' ? 'desc' : 'asc', page: 0 }, () => this.loadData());
  };

  cleanFilters = () => {
    sessionStorage.removeItem('property-list-params');
    window.location.reload();
  };

  handleChangeCategory = (categoryId: number) => {
    if (this.state.loading) return;
    this.setState({ categoryId, page: 0 }, () => this.loadData());
  };

  handleChangeOwnerTypeId = (ownerTypeId: number) => {
    if (this.state.loading) return;
    this.setState({ ownerTypeId, page: 0 }, () => this.loadData());
  };

  handleChangeOwnerAcceptExchange = (ownerAcceptExchange: 'yes' | 'no') => {
    if (this.state.loading) return;
    this.setState(
      {
        ownerAcceptExchange: ownerAcceptExchange === 'yes' ? true : ownerAcceptExchange === 'no' ? false : null,
        page: 0
      },
      () => this.loadData()
    );
  };

  handleChangeImovelWeb = (imovelWeb: 'enabled' | 'disabled') => {
    if (this.state.loading) return;
    this.setState({ imovelWeb, page: 0 }, () => this.loadData());
  };

  handleChangeStatus = (status: enPropertyStatus) => {
    if (this.state.loading) return;
    this.setState({ status, page: 0 }, () => this.loadData());
  };

  handleChangeMinSalesValue = (minSalesValue: number) => {
    if (this.state.loading) return;
    this.setState({ minSalesValue: minSalesValue === 0 ? null : minSalesValue, page: 0 }, () => this.loadData());
  };

  handleChangeMaxSalesValue = (maxSalesValue: number) => {
    if (this.state.loading) return;
    this.setState({ maxSalesValue: maxSalesValue === 0 ? null : maxSalesValue, page: 0 }, () => this.loadData());
  };

  handleCreate = () => this.props.history.push('/imoveis/novo');
  handleEdit = (property: IProperty) => this.props.history.push(`/imoveis/${property.id}/editar`);

  handleDelete = async (property: IProperty) => {
    const isOk = await Alert.confirm(`Deseja realmente apagar esse ${property.title}?`);
    if (!isOk) return;

    propertyService
      .remove(property.id)
      .pipe(loader(), bindComponent(this))
      .subscribe({
        next: () => this.loadData(),
        error: err => Toast.error(err)
      });
  };

  handleUpdateStatus = async (property: IProperty, status: enPropertyStatus) => {
    const isOk = await Alert.confirm(`Deseja realmente alterar o status para ${formatStatus(status)}?`);
    if (!isOk) return;

    propertyService
      .statusUpdate(property.id, status)
      .pipe(loader(), bindComponent(this))
      .subscribe({
        next: () => this.loadData(),
        error: err => Toast.error(err)
      });
  };

  handleFeatured = (property: IProperty) => this.setState({ property, showFeatured: true });
  handleDetails = (property: IProperty) => this.setState({ property, showDetails: true });

  handleCompletedFeature = () => this.setState({ showFeatured: false }, () => this.loadData());
  handleCancelFeature = () => this.setState({ showFeatured: false });
  handleCloseDetails = () => this.setState({ showDetails: false });

  render() {
    const {
      categoryId,
      items,
      error,
      orderBy,
      orderDirection,
      status,
      loading,
      property,
      categories,
      ownerTypes,
      ownerTypeId,
      ownerAcceptExchange,
      loadingCategories,
      loadingOwnerTypes,
      minSalesValue,
      maxSalesValue,
      showFeatured,
      showDetails
    } = this.state;
    const { classes } = this.props;

    return (
      <Fragment>
        <Toolbar title='Imóveis' />

        <Card>
          <FabButton actions={this.actions} />
          {this.renderLoader()}

          <PropertyFeaturedDialog
            opened={showFeatured}
            property={property}
            onCancel={this.handleCancelFeature}
            onCompleted={this.handleCompletedFeature}
          />

          <PropertyDetailsDialog opened={showDetails} property={property} onClose={this.handleCloseDetails} />

          <CardContent>
            <Grid container spacing={2} alignItems='flex-end'>
              <Grid item xs={12} sm={12} md={8} lg={6}>
                {this.renderSearch()}
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={2}>
                <FieldSelect
                  label='Status'
                  value={status}
                  options={this.statuses}
                  emptyOption='Todos'
                  onChange={this.handleChangeStatus}
                  margin='none'
                />
              </Grid>
              <Grid item xs={true} lg={3}>
                <FieldSelect
                  label='Ordenar por'
                  value={orderBy}
                  options={this.orderByColumns}
                  onChange={this.handleChangeOrderBy}
                  margin='none'
                />
              </Grid>
              <Grid item xs='auto'>
                <IconButton className={classes.orderIcon} onClick={this.handleToggleOrderDirection}>
                  {orderDirection === 'asc' ? <ArrowDownIcon /> : <ArrowUpIcon />}
                </IconButton>
              </Grid>
              <Grid item xs={12} sm={4} lg={2}>
                <FieldSelect
                  label='Categoria'
                  value={categoryId}
                  emptyOption='Todos'
                  loading={loadingCategories}
                  options={categories}
                  onChange={this.handleChangeCategory}
                  margin='none'
                />
              </Grid>
              <Grid item xs={12} sm={4} lg={2}>
                <FieldSelect
                  label='Classificação do Proprietário'
                  value={ownerTypeId}
                  emptyOption='Todos'
                  loading={loadingOwnerTypes}
                  options={ownerTypes}
                  onChange={this.handleChangeOwnerTypeId}
                  margin='none'
                />
              </Grid>
              <Grid item xs={12} sm={4} lg={2}>
                <FieldSelect
                  label='Aceita Permuta'
                  value={ownerAcceptExchange === true ? 'yes' : ownerAcceptExchange === false ? 'no' : null}
                  emptyOption='Todos'
                  options={this.acceptExchangeOptiosn}
                  onChange={this.handleChangeOwnerAcceptExchange}
                  margin='none'
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} className={classes.salesPrice}>
                <FieldText
                  label='Valor de Venda'
                  placeholder='Mínimo'
                  value={minSalesValue}
                  onChange={this.handleChangeMinSalesValue}
                  fullWidth={false}
                  mask='money'
                  margin='none'
                />
                <FieldText
                  label=' '
                  placeholder='Máximo'
                  value={maxSalesValue}
                  fullWidth={false}
                  onChange={this.handleChangeMaxSalesValue}
                  mask='money'
                  margin='none'
                />
              </Grid>
              <Grid item xs='auto'>
                <Tooltip title='Limpar Filtros'>
                  <IconButton onClick={this.cleanFilters}>
                    <BroomIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>

            <ErrorMessage error={error} tryAgain={this.handleTryAgain} />
          </CardContent>

          {!loading && !items.length && !error && (
            <Fragment>
              <Divider />
              <CardContent>
                <Typography className={classes.emptyMessage}>Nenhum imóvel encontrado...</Typography>
              </CardContent>
            </Fragment>
          )}

          {items.map(i => (
            <PropertyListItem
              key={i.id}
              property={i}
              onEdit={this.handleEdit}
              onDelete={this.handleDelete}
              onFeatured={this.handleFeatured}
              onDetails={this.handleDetails}
              onUpdateStatus={this.handleUpdateStatus}
            />
          ))}

          <Divider />
          {this.renderTablePagination()}
        </Card>
      </Fragment>
    );
  }
}
