/* eslint-disable no-restricted-imports */
import { PureComponent } from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import ErrorMessage from 'components/Shared/ErrorMessage';
import { IStyledProps, WithStyles } from 'decorators/withStyles';
import { formatStatus, formatStatusIcon } from 'formatters/property';
import { IDashboardTotals } from 'interfaces/models/dashboard';
import { enPropertyStatus } from 'interfaces/models/property';
import { MdiReactIconProps } from 'mdi-react';
import ChartLineIcon from 'mdi-react/ChartLineIcon';
import bindComponent from 'rxjs-operators/bindComponent';
import dashboardService from 'services/dashbord';

interface IState extends Partial<IDashboardTotals> {
  loading: boolean;
  error?: any;
}

@WithStyles(theme => ({
  wrapper: {
    height: '100%'
  },
  icon: {
    marginRight: 10,
    verticalAlign: 'sub'
  },
  iconSmall: {
    width: 20,
    height: 20,
    marginRight: 5,
    verticalAlign: 'top',
    opacity: 0.8
  },
  iconcompleted: {
    color: theme.variables.colors.statusCompleted
  },
  iconcanceled: {
    color: theme.variables.colors.statusCanceled
  }
}))
export default class Total extends PureComponent<IStyledProps, IState> {
  constructor(props: IStyledProps) {
    super(props);
    this.state = { loading: true };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState({ loading: true, error: null });

    dashboardService
      .totals()
      .pipe(bindComponent(this))
      .subscribe({
        next: data => this.setState({ loading: false, ...(data ?? {}) }),
        error: error => this.setState({ loading: false, error })
      });
  };

  render() {
    const { loading, error, total, pending, completed, canceled } = this.state;
    const { classes } = this.props;

    return (
      <Card className={classes.wrapper}>
        {loading && <LinearProgress variant='indeterminate' />}

        {!!error && (
          <CardContent>
            <ErrorMessage error={error} tryAgain={this.loadData} />
          </CardContent>
        )}

        {!error && (
          <Grid container>
            <Grid item xs={6} sm={12} lg={4}>
              <CardContent>
                <Typography color='textSecondary' gutterBottom>
                  Total
                </Typography>
                <Typography variant='h5'>
                  <ChartLineIcon className={classes.icon} />
                  {total}
                </Typography>
              </CardContent>
            </Grid>
            <Grid item xs={6} lg={8}>
              <CardContent>
                <Typography>
                  <StatusIcon className={classes.iconSmall} status={enPropertyStatus.pending} />
                  {pending} Ativos
                </Typography>
                <Typography>
                  <StatusIcon
                    className={`${classes.iconSmall} ${classes['icon' + enPropertyStatus.completed]}`}
                    status={enPropertyStatus.completed}
                  />
                  {completed} {formatStatus(enPropertyStatus.completed, true)}
                </Typography>
                <Typography>
                  <StatusIcon
                    className={`${classes.iconSmall} ${classes['icon' + enPropertyStatus.canceled]}`}
                    status={enPropertyStatus.canceled}
                  />
                  {canceled} {formatStatus(enPropertyStatus.canceled, true)}
                </Typography>
              </CardContent>
            </Grid>
          </Grid>
        )}
      </Card>
    );
  }
}

function StatusIcon({ status, ...props }: { status: enPropertyStatus } & MdiReactIconProps) {
  const Icon = formatStatusIcon(status);

  return <Icon {...props} />;
}
